const useLoginOrProfile = () => {
  const tokenData = sessionStorage.getItem('token');

  if (!tokenData) {
    return 'login';
  } else {
    const timestamp = parseInt(JSON.parse(tokenData).timestamp);
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timestamp;
    if (timeDifference > 60 * 60 * 1000) {
      return 'login';
    } else {
      return 'profile';
    }
  }
};

export default useLoginOrProfile;
