import { Rating } from 'primereact/rating';
import { Carousel } from 'react-responsive-carousel';
import { indicatorStyles } from '../../styles/products';

const TopCarousel = ({ products }) => (
  <Carousel
    autoPlay={true}
    transitionTime={500}
    stopOnHover={true}
    swipeable={true}
    infiniteLoop={true}
    showThumbs={false}
    interval={4000}
    showStatus={false}
    renderIndicator={(onClickHandler, isSelected, index, label) => {
      if (isSelected) {
        return (
          <button aria-label={`Selected: ${label} ${index + 1}`} title={`Selected: ${label} ${index + 1}`}>
            <img src="/assets/svg/carousel-indicator.svg" alt="" style={{ ...indicatorStyles }} />
          </button>
        );
      }
      return (
        <button
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          tabIndex={0}
          title={`${label} ${index + 1}`}
          aria-label={`${label} ${index + 1}`}
          value={index}
          key={index}
        >
          <img src="/assets/svg/carousel-indicator-disabled.svg" alt="" style={indicatorStyles} role="button" />
        </button>
      );
    }}
  >
    {products?.map(product => (
      <div className="flex pt-20 pl-28 flex-col items-center justify-center font-theme-1 w-full bg-theme-3">
        <div className="flex flex-row items-center justify-start w-full">
          <div className="grid grid-cols-carousel-1 gap-8 w-full">
            <div className="flex flex-row items-center justify-center py-16">
              <img className="w-auto h-72 rounded" alt="" src={product?.images.length > 0 ? product.images[0] : '/assets/images/999192.jpg'} />
            </div>
            <div className="flex flex-col items-start pt-20 justify-start w-full">
              <div className="py-0">
                <p className="text-xs font-semibold">Banglore, Karnataka</p>
              </div>
              <div className="pb-6 pt-1">
                <h2 className="text-theme-2 text-3.5xl font-bold">{product.name}</h2>
              </div>
              <div>
                <Rating className="text-theme-2" value={4} readOnly stars={5} cancel={false} />
              </div>
              <div className="py-2">
                <p className="text-theme-2 text-sm">Total Number of Impressions: 10000</p>
              </div>
              <div className="py-2">
                <p className="text-theme-2 text-sm">Total Places: 100</p>
              </div>
              <div className="py-2">
                <p className="text-theme-2 text-sm">Total Number of Impressions: 1000000</p>
              </div>
              <button className="bg-theme-2 text-white font-semibold py-2 my-2 px-4 rounded-md">Book Now</button>
            </div>
          </div>
        </div>
      </div>
    ))}
  </Carousel>
);

export default TopCarousel;
