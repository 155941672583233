import apiHelper from '../../helper/apiHelper';

const bookingConfirmedApi = async (data, token) => {
  return await apiHelper('/booking/confirm', 'POST', data, token)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default bookingConfirmedApi;
