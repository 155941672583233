import apiHelper from '../../helper/apiHelper';

const getUser = async token => {
  const userData = await apiHelper('/auth/user/', 'GET', null, token)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
  return userData;
};

export default getUser;
