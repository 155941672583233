import apiHelper from '../../helper/apiHelper';

const getProductsApi = async filters => {
  // make a request to the server with the filters
  let string = '/product/';
  if (filters) {
    string += '?';
    Object.keys(filters).forEach(key => {
      string += `${key}=${filters[key]}&`;
    });
  }
  const products = await apiHelper(string, 'GET', null, null)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
  return products;
};

export default getProductsApi;
