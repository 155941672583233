import { Dialog } from 'primereact/dialog';

const HelpModal = ({ visible, setVisible, submit }) => {
  return (
    <Dialog visible={visible} onHide={() => setVisible(false)} header="Help" closeOnEscape={true}>
      <div className="flex flex-col">
        <h4 className="text-theme-night-black font-bold">Please share your query and we will get back to you as soon as we can.</h4>
        <form className="flex flex-col" onSubmit={e => submit(e)}>
          <div className="grid grid-cols-2 gap-6 py-8">
            <div className="grid grid-flow-row gap-2">
              <div>
                <label className="text-theme-night-black">Phone</label>
              </div>
              <div>
                <input
                  type="number"
                  minLength={10}
                  className="border border-theme-night-black py-3 px-6 rounded w-80"
                  placeholder="Please enter your phone number"
                  required
                />
              </div>
            </div>
            <div className="grid grid-flow-row gap-2">
              <div>
                <label className="text-theme-night-black">Email</label>
              </div>
              <div>
                <input type="email" className="border border-theme-night-black py-3 px-6 rounded w-80" placeholder="Please enter your email" />
              </div>
            </div>
          </div>
          <label className="text-theme-night-black pb-2">Description</label>
          <textarea className="border border-theme-night-black py-3 px-6 rounded h-28 resize-none" placeholder="Please enter your message" />
          <div className="flex flex-row justify-end w-full pt-4">
            <button className="bg-theme-orange-1 text-white font-bold py-2 px-8 rounded" onClick={() => setVisible(false)} type="button">
              Cancel
            </button>
            <button className="bg-theme-purple-1 text-white font-bold py-2 px-8 rounded ml-4" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default HelpModal;
