import HomeNavbar from '../components/Navbars/HomeNavbar';
import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
import OrderSummary from '../components/OrderSummary'; // eslint-disable-line no-unused-vars

const CartScreen = ({
  cart,
  verifyBookingDates,
  calculateTotalPriceOfCampaignData,
  differenceBetweenTwoDatesOfCampaign,
  calculateTotalPriceOfCustomizedCampaign,
  totalPrice,
  removeFromCart
}) => {
  return (
    <>
      <div className="w-full font-theme-2 pb-20">
        <HomeNavbar />
        <form onSubmit={verifyBookingDates}>
          <div className="flex flex-col w-full px-8 pt-24">
            <div className="flex flex-row justify-center items-center w-full">
              <div className="flex flex-col items-center py-8 justify-center w-full">
                <h2 className="text-3.5xl font-bold">My Cart</h2>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-col w-full bg-white rounded-b-xl pb-6">
                <div className="grid grid-cols-11/20 gap-16">
                  <div className="pt-4">
                    {cart.length > 0 ? (
                      <div className="grid grid-cols-1 gap-8">
                        {cart.map(item => (
                          <div className="shadow-lg">
                            <div className="flex flex-col w-full px-4 py-2">
                              <div className="flex flex-col w-full">
                                <div className="grid gap-4 grid-cols-1 w-full py-2">
                                  <div className="grid grid-cols-1/3 gap-4 w-full">
                                    <div className="w-full bg-theme-5 rounded h-full"></div>
                                    <div className="w-full grid grid-cols-11/20 gap-8">
                                      <div className="w-full">
                                        <h6 className="text-lg text-theme-night-black-2 font-bold">{item.name}</h6>
                                        <p className="text-sm font-normal text-theme-gray-4">M G Road TOI Building Towards Brigade Road</p>
                                        <p className="text-lg pt-3 pb-10 font-bold">
                                          ₹{' '}
                                          {item.type === 'customized'
                                            ? calculateTotalPriceOfCustomizedCampaign()
                                            : calculateTotalPriceOfCampaignData(item)}
                                        </p>
                                      </div>
                                      <div className="w-full">
                                        <div className="grid grid-flow-row gap-4">
                                          <div className="flex flex-col w-full">
                                            <div className="grid grid-flow-row gap-2 w-full">
                                              <p className="text-sm font-normal text-theme-gray-4">Size</p>
                                              <p className="text-theme-night-black-2">50 X 50 ft</p>
                                            </div>
                                          </div>
                                          <div className="flex flex-col w-full">
                                            <div className="grid grid-flow-row gap-2 w-full">
                                              <p className="text-sm font-normal text-theme-gray-4">
                                                Duration: {differenceBetweenTwoDatesOfCampaign(item)} days
                                              </p>
                                              <p className="text-theme-night-black-2"></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grid grid-flow-col gap-4 items-center justify-end w-full">
                                    <button
                                      type="button"
                                      className="border-theme-cherry-red-1 text-theme-cherry-red-1 bg-transparent font-bold py-1.5 px-8 rounded-lg focus-within:outline-none border-2"
                                      onClick={() => removeFromCart(null, item.id)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex flex-col w-full border rounded-lg px-8 py-4 border-black">
                        <div className="flex flex-col w-full text-xl text-bold">Cart Empty</div>
                      </div>
                    )}
                  </div>
                  <OrderSummary
                    calculateTotalPriceOfCampaignData={calculateTotalPriceOfCampaignData}
                    calculateTotalPriceOfCustomizedCampaign={calculateTotalPriceOfCustomizedCampaign}
                    cart={cart}
                    isVisible={true}
                    totalPrice={totalPrice}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
      <Copyright />
    </>
  );
};

export default CartScreen;
