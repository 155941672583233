import { BrowserRouter, Route, Routes } from 'react-router-dom';
import VerifyMobile from '../containers/VerifyMobile';
import { AuthProvider } from '../contexts/AuthContext';
import { CartProvider } from '../contexts/CartContext';
import Products from '../containers/Products';
import Customize from '../containers/Customize';
import Product from '../containers/Product';
import Cart from '../containers/Cart';
import UserDetails from '../containers/UserDetails';
import Home from '../containers/Home';
import TermsConditions from '../views/TermsConditions';
import PrivacyPolicy from '../views/PrivacyPolicy';
import CampaignProducts from '../containers/CampaignProducts';
import { CustomizedCampaignProvider } from '../contexts/CustomizedCampaignContext';
import CustomizedCampaignPreview from '../containers/CustomizedCampaignPreview';
import Campaigns from '../containers/Campaigns';
import { CampaignDataProvider } from '../contexts/CampaignData';
import MyOrders from '../containers/MyOrders';
import Profile from '../containers/Profile';

const Router = ({ children }) => {
  return (
    <BrowserRouter>
      {children}
      <AuthProvider>
        <CartProvider>
          <CustomizedCampaignProvider>
            <CampaignDataProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/verify-mobile" element={<VerifyMobile />} />
                <Route path="/products" element={<Products />} />
                <Route path="/customize" element={<Customize />} />
                <Route path="/campaigns" element={<Campaigns />} />
                <Route path="/campaign/:campaignId/product/:productId" element={<Product type={'campaign'} />} />
                <Route path="/product/:productId" element={<Product type={'product'} />} />
                <Route path="/customized-campaign/product/:productId" element={<Product type={'customizedCampaign'} />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/user-details" element={<UserDetails />} />
                <Route path="/terms-and-conditions" element={<TermsConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/campaign/:campaignId" element={<CampaignProducts />} />
                <Route path="/customized-campaign-preview" element={<CustomizedCampaignPreview />} />
                <Route path="/my-orders" element={<MyOrders />} />
                <Route path="/profile" element={<Profile />} />
              </Routes>
            </CampaignDataProvider>
          </CustomizedCampaignProvider>
        </CartProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
