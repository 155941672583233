import { useContext, useState, useEffect, createContext, useCallback, memo } from 'react';
import firebaseConfig from '../config/firebase';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import loginWithPhone from '../api/loginWithPhone';

const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

initializeApp(firebaseConfig);
const auth = getAuth();

export const AuthProvider = memo(({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-up-mobile-recaptcha', { size: 'invisible' }, auth);
    return () => {
      window.recaptchaVerifier.clear();
    };
  }, []);

  const signupMobile = useCallback(async phoneNumber => {
    let promise = signInWithPhoneNumber(auth, '+91' + phoneNumber, window.recaptchaVerifier)
      .then(ref => {
        window.confirmationResult = ref;
        return ref;
      })
      .catch(error => {
        return error;
      });
    return promise;
  }, []);

  const confirmOtp = useCallback(async code => {
    let promise = await window.confirmationResult.confirm(code).then(async user => {
      const token = user.user.accessToken;
      const phone_number = user.user.phoneNumber;
      setToken(user.user.accessToken);
      sessionStorage.setItem('token', JSON.stringify({ token, timestamp: new Date().getTime(), phone_number }));
      setTimeout(() => {
        sessionStorage.removeItem('token');
      }, 60 * 60 * 1000);
      const userData = await loginWithPhone({ phone_number }, token);
      setCurrentUser({ ...user, userData: userData.data });
      sessionStorage.setItem('user_details', JSON.stringify({
         userData: userData.data
      }))
      setIsAuthenticated(true);
      return user;
    });
    return promise;
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      const jwtToken = JSON.parse(sessionStorage.getItem('token')).token;
      setToken(jwtToken);
      setIsAuthenticated(true);
    }
  }, []);

  const value = {
    currentUser,
    isAuthenticated,
    token,
    setToken,
    setIsAuthenticated,
    signupMobile,
    setCurrentUser,
    confirmOtp
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      <div id="sign-up-mobile-recaptcha"></div>
    </AuthContext.Provider>
  );
});
