const specificationsKeys = [
  { name: 'Lamp Post ID', key: 'product_id' },
  { name: 'Category', key: 'category' },
  { name: 'Dimensions', key: 'image_size' },
  { name: 'Format Support', key: 'image_format' },
  { name: 'Lighting/Non-Lighting', key: 'lighting' },
  { name: 'State', key: 'state' },
  { name: 'District', key: 'district' },
  { name: 'Address', key: 'address' },
  { name: 'Impression', key: 'impression' }
];

export default specificationsKeys;
