const firebaseConfig = {
  apiKey: "AIzaSyBnT4Ba18y1IrFzKejhDHajKQ6HRGgGYNw",
  authDomain: "adarth-development.firebaseapp.com",
  projectId: "adarth-development",
  storageBucket: "adarth-development.appspot.com",
  messagingSenderId: "197796451941",
  appId: "1:197796451941:web:e745f431edbd3b73a6588c",
  measurementId: "G-EH266MD2LH"
};

export default firebaseConfig;
