import HomeNavbar from '../components/Navbars/HomeNavbar';
import InputsCheckout from '../constants/InputsCheckout';

const ProfileScreen = ({ active, setActive, inputsDisabled = true, setInputsDisabled, userData, setUserData, updateUserData }) => {
  return (
    <div className="w-full min-h-screen font-theme-2">
      <HomeNavbar />
      <div className="flex flex-col items-center justify-center w-full pt-24">
        <div className="flex flex-col items-center justify-center w-full">
          <h2 className="text-theme-night-black-2 text-center font-bold text-3.5xl py-4">My Profile</h2>
        </div>
        <div className="grid grid-flow-col gap-4 justify-center w-full">
          {InputsCheckout.map((input, index) => (
            <button
              key={index}
              className={`border-b-2 px-2 ${active === input.type ? 'border-theme-purple-1' : 'border-theme-night-black-2'}`}
              onClick={() => {
                setActive(input.type);
                setInputsDisabled(true);
              }}
            >
              {input.type}
            </button>
          ))}
        </div>
        <div className="flex flex-col gap-4 justify-center items-center w-full py-8">
          <div className="flex flex-col items-left justify-left w-1/2">
            <div className="flex flex-row items-start justify-between w-full">
              {InputsCheckout.filter(input => input.type === active).map((input, index) => (
                <h2 key={index} className="text-theme-night-black-2 font-bold text-2xl py-2">
                  {input.type}
                </h2>
              ))}
              <p className="text-theme-orange-1 font-bold py-2 cursor-pointer" onClick={() => setInputsDisabled(false)}>
                Edit
              </p>
            </div>
          </div>
          {userData &&
            InputsCheckout.find(input => input.type === active).fields.map((input, index) => (
              <div key={index} className="flex flex-col w-1/2">
                <label className="text-theme-night-black-2 my-1">{input.label}</label>
                <input
                  type="text"
                  className="w-full border border-theme-night-black-2 py-3 px-6 rounded"
                  placeholder={input.placeholder}
                  disabled={inputsDisabled}
                  value={userData[input?.name]}
                  onChange={e => {
                    setUserData({ ...userData, [input?.name]: e.target.value });
                  }}
                />
              </div>
            ))}
        </div>
        <div className="flex flex-row gap-4 justify-left items-left w-1/2">
          <button className="py-2 px-4 border-2 bg-theme-purple-1 font-bold text-white rounded-lg" onClick={updateUserData}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileScreen;
