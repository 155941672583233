import { useCustomizedCampaignContext } from '../contexts/CustomizedCampaignContext';

const useDates = () => {
  const { customizedCampaign } = useCustomizedCampaignContext();

  const getDifferenceBetweenDates = (date1, date2, type = 'text') => {
    if (!date1 || !date2) {
      return 'Dates Not Selected';
    }

    const difference = new Date(date2).getTime() - new Date(date1).getTime();
    const days = Math.ceil(difference / (1000 * 60 * 60 * 24));
    if (type === 'value') {
      return days;
    }
    return days + ' days';
  };

  const getValueOfDate = date => {
    if (!date) {
      return '';
    }
    return new Date(date);
  };

  const getUnavailableDates = itemId => {
    const item = customizedCampaign.products.find(item => item.product.id === itemId);
    const unavailableDates = item?.product.unavailable_dates.map(date => new Date(date));
    if (!unavailableDates) {
      return [];
    }
    return unavailableDates;
  };

  const getMinimumEndDay = itemId => {
    const unavailableDates = getUnavailableDates(itemId);
    const minEndDay = new Date(new Date(new Date().setDate(new Date().getDate() + 7)).setHours(5, 30, 0, 0));

    if (!unavailableDates) {
      return minEndDay;
    }

    let endDay = minEndDay;
    for (let i = 0; i < unavailableDates.length - 1; i++) {
      if (i === unavailableDates.length - 2) {
        endDay = new Date(unavailableDates[i + 1]);
        endDay.setDate(endDay.getDate() + 1);
        break;
      }
      if (unavailableDates[i + 1] - unavailableDates[i] === 1 * 24 * 60 * 60 * 1000) {
        continue;
      } else {
        endDay = new Date(unavailableDates[i].getTime() + 1 * 24 * 60 * 60 * 1000);
        break;
      }
    }
    let returnDate = null;
    if (minEndDay > endDay) {
      returnDate = minEndDay;
    } else {
      returnDate = endDay;
    }

    return new Date(returnDate.getMonth() + 1 + '/' + returnDate.getDate() + '/' + returnDate.getFullYear());
  };

  const getMaximumEndDay = (itemId, from_date) => {
    const unavailableDates = getUnavailableDates(itemId);
    const unavailableDatesArray = unavailableDates.map(date => new Date(date).getTime());
    const from_date_timestamp = new Date(new Date(from_date).setHours(5, 30, 0, 0)).getTime();
    if (unavailableDatesArray[unavailableDatesArray.length - 1] <= from_date_timestamp) {
      return new Date(new Date(from_date).setFullYear(new Date(from_date).getFullYear() + 1));
    } else {
      // find nearest unavailable date from from_date by binary search
      let left = 0;
      let right = unavailableDatesArray.length - 1;
      while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        if (unavailableDatesArray[mid] <= from_date_timestamp) {
          left = mid + 1;
        } else {
          right = mid - 1;
        }
      }
    }
  };

  const getBookingDuration = booking_duration => {
    let duration = 0;
    if (typeof booking_duration !== 'string' && booking_duration >= 86400) {
      duration = Math.floor(booking_duration / 86400);
    } else if (typeof booking_duration === 'number') {
      duration = booking_duration;
    } else {
      duration = parseInt(booking_duration.split(' ')[0]);
    }
    return duration;
  };

  const getMinimumEndDayOfItem = (from_date, booking_duration) => {
    const date = from_date ? new Date(from_date) : new Date();
    date.setDate(date.getDate() + getBookingDuration(booking_duration));
    return date;
  };

  const convertDatesArray = dates => dates.map(date => new Date(date));

  return {
    getDifferenceBetweenDates,
    getValueOfDate,
    getUnavailableDates,
    getMinimumEndDay,
    getMinimumEndDayOfItem,
    getMaximumEndDay,
    getBookingDuration,
    convertDatesArray
  };
};

export default useDates;
