import { Calendar } from 'primereact/calendar';
import monthNavigatorTemplate from './MonthNavigatorTemplate';
import yearNavigatorTemplate from './YearNavigatorTemplate';

const CalendarTemplate = props => {
  return (
    <Calendar
      readOnlyInput={true}
      className="w-48 h-10"
      inputClassName="py-2 px-2 hover:border-theme-gray-4 input-product-calendar"
      icon={<i className="pi pi-calendar text-theme-gray-4 text-xl" />}
      showIcon
      yearRange="2022:2070"
      iconPos="left"
      monthNavigator
      yearNavigator
      monthNavigatorTemplate={monthNavigatorTemplate}
      yearNavigatorTemplate={yearNavigatorTemplate}
      {...props}
    />
  );
};

export default CalendarTemplate;
