import { createContext, useContext, useEffect, useState } from 'react';

const CampaignDataContext = createContext();

const useCampaignDataContext = () => {
  return useContext(CampaignDataContext);
};

const CampaignDataProvider = ({ children }) => {
  const [campaignData, setCampaignData] = useState(
    JSON.parse(localStorage.getItem('campaignData')) || {
      campaigns: []
    }
  );

  useEffect(() => {
    localStorage.setItem('campaignData', JSON.stringify(campaignData));
  }, [campaignData]);

  return <CampaignDataContext.Provider value={{ campaignData, setCampaignData }}>{children}</CampaignDataContext.Provider>;
};

export { CampaignDataProvider, useCampaignDataContext };
