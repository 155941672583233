import axios from 'axios';
import domain from '../constants/domain';

const apiHelper = async (url, method, data, tokenData) => {
  const result = axios({
    method,
    url: domain + url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + tokenData
    },
    ...(method === 'GET' ? { params: data } : { data })
  })
    .then(res => {
      return res;
    })
    .catch(err => {
      if (err.response.status === 401) {
        window.location.href = '/verify-mobile';
      }
      throw err;
    });
  return result;
};

export default apiHelper;
