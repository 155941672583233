import { useCallback } from 'react';
import useDates from './dates';

const useCampaignDateHandlers = () => {
  const { getBookingDuration } = useDates();

  const findMaximumBookingDurationInCampaign = useCallback(
    campaign => {
      const maximumBookingDuration = campaign.products.reduce((acc, item) => {
        let { booking_duration } = item.product;
        booking_duration = getBookingDuration(booking_duration);
        if (acc < booking_duration) {
          return booking_duration;
        }
        return acc;
      }, 0);
      return maximumBookingDuration;
    },
    [getBookingDuration]
  );

  const findMinimumFromDateInCampaign = useCallback(campaign => {
    const minimumFromDate = campaign.products.reduce((acc, item) => {
      const { from_date } = item;
      if (acc === null) {
        return from_date;
      }
      if (acc > from_date) {
        return from_date;
      }
      return acc;
    }, null);

    return minimumFromDate;
  }, []);

  const findMaximumToDateInCampaign = useCallback(campaign => {
    const maximumToDate = campaign.products.reduce((acc, item) => {
      const { to_date } = item;
      if (acc === null) {
        return to_date;
      }
      if (acc < to_date) {
        return to_date;
      }
      return acc;
    }, null);
    return maximumToDate || '';
  }, []);

  const getMinimumStartDayForCampaign = useCallback(() => new Date(new Date().setDate(new Date().getDate() + 7)), []);

  const getMinimumEndDayForCampaign = useCallback(
    campaign => {
      const minimumFromDate = new Date(findMinimumFromDateInCampaign(campaign));
      return new Date(minimumFromDate.setDate(minimumFromDate.getDate() + findMaximumBookingDurationInCampaign(campaign)));
    },
    [findMaximumBookingDurationInCampaign, findMinimumFromDateInCampaign]
  );

  return {
    findMaximumBookingDurationInCampaign,
    findMinimumFromDateInCampaign,
    findMaximumToDateInCampaign,
    getMinimumStartDayForCampaign,
    getMinimumEndDayForCampaign
  };
};

export default useCampaignDateHandlers;
