import HomeNavbar from '../components/Navbars/HomeNavbar';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Copyright from '../components/Copyright';
import Testimonial from '../components/Testimonial';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

const HomeScreen = ({ scrollRef, scrollClick, onContactSubmit, campaigns, products }) => {
  return (
    <div className="w-full font-theme-2">
      <HomeNavbar theme="orange" text={'white'} transparent={true} />
      <div
        className="w-full h-screen bg-cover bg-center bg-no-repeat flex flex-col justify-center items-start px-32"
        style={{
          backgroundImage: 'url(/assets/images/homepage/layer2.png), url(/assets/images/homepage/layer1.png), url(/assets/images/homepage/layer3.png)'
        }}
      >
        <div className="flex flex-col items-start justify-center w-2/3">
          <h3 className="text-6xl text-theme-gray-1 font-bold">
            OOH Advertising Made{' '}
            <span className="text-white">
              <Typewriter
                options={{
                  strings: ['Transparent', 'Easy', 'Hassle-Free', 'Data Driven', 'Impactful'],
                  autoStart: true,
                  loop: true
                }}
              />
            </span>
          </h3>
          <p className="text-theme-gray-2 text-lg py-8">
            India’s first data-driven platform that automates your OOH Advertisements. From selecting the right spaces to Tracking the progress of the
            campaign, get a deeper insight into each penny you spend..
          </p>
          <button
            onClick={scrollClick}
            className="py-6 flex flex-col px-4 border-2 border-white bg-theme-orange-1 text-theme-white font-bold rounded-full"
          >
            <i className="pi pi-chevron-down text-white" />
            <i className="pi pi-chevron-down text-white -mt-2" />
          </button>
        </div>
      </div>

      <div className="flex flex-col w-full px-28 py-16" ref={scrollRef}>
        <div className="flex flex-row justify-between w-full">
          <h5 className="text-3.5xl font-bold">
            Some Recommended <span className="text-theme-purple-1">Campaigns</span>
          </h5>
          <Link to="/campaigns">
            <div className="flex flex-row items-center justify-center w-fit">
              <p className="text-black font-bold text-normal">View All</p>
              <i className="pi pi-arrow-right text-theme-purple-1 px-2 tex-normal" />
            </div>
          </Link>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-1 gap-6 py-10">
          {campaigns.map((campaign, i) => (
            <div key={i} className="flex flex-col w-full shadow-2xl relative overflow-hidden">
              <div
                className="h-48 bg-cover relative bg-no-repeat bg-center"
                style={{
                  backgroundImage: `url(${campaign.images[0]})`
                }}
              ></div>
              <div className="flex flex-col px-4 py-4">
                <p className="font-bold text-lg w-3/4 py-2">{campaign.name}</p>
                <div className="grid grid-cols-2 gap-8 pt-2">
                  <div className="flex flex-col items-start justify-center">
                    <div className="text-theme-gray-3 text-sm pb-2">No. of Media</div>
                    <div className="flex flex-row items-center text-normal justify-start">{campaign.total_locations}</div>
                  </div>
                  <div className="flex flex-col items-start justify-center">
                    <div className="text-theme-gray-3 text-sm pb-2">Impressions</div>
                    <div className="flex flex-row items-center text-normal justify-start">{campaign.impressions}</div>
                  </div>
                </div>
                <p className="font-bold text-2xl pt-6 ">₹ {campaign.price}</p>
                <Link to={"/campaign/" + campaign.id}>
                <p className="py-4 flex flex-row items-center justify-start text-sm font-bold">
                  View Details <i className="pi pi-arrow-right text-theme-purple-1 px-2 tex-normal" />
                </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col w-full pl-28 pr-4 py-16 bg-theme-13">
        <div className="grid grid-cols-1/3 w-full ">
          <div className="flex flex-col items-start justify-center w-full pr-24">
            <h5 className="text-3.5xl font-bold pb-2">
              Customize Your
              <br /> Own <span className="text-theme-purple-1">Campaigns</span>
            </h5>
            <p className="pb-1.5 text-theme-night-black">
            From  budget to location, select your key factors from a wide range of filters available. Adarth offers complete customization of your OOH Ads Campaign. 
            </p>
          </div>
          <div className="flex flex-col w-full p-4">
            <div className="flex flex-row justify-between items-center w-full">
              <div className="grid grid-cols-3 gap-4 w-full">
                <div className="bg-white flex flex-col justify-between rounded-2xl pt-12 px-6 relative">
                  <div className="relative">
                    <img src="/assets/images/board1.png" alt="star" className="z-20 relative" />
                    <div className="absolute -top-4 -left-2 rounded-full p-16 bg-theme-13" />
                  </div>
                  <div className="flex flex-col justify-between pt-8">
                    <p className="text-lg font-bold">Digital Billboard Advertising</p>
                    <Link to={'/customize'}>
                      <p className="py-4 flex flex-row items-center justify-start">
                        View all <i className="pi pi-arrow-right text-theme-purple-1 px-2" />
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="bg-white flex flex-col justify-between rounded-2xl pt-12 px-6 relative">
                  <div className="relative">
                    <img src="/assets/images/board3.png" alt="star" className="z-20 relative" />
                    <div className="absolute -top-4 -left-2 rounded-full p-16 bg-theme-13" />
                  </div>
                  <div className="flex flex-col justify-between pt-8">
                    <p className="text-lg font-bold">
                      Lamp Post <br /> Advertising
                    </p>
                    <Link to={'/customize'}>
                      <p className="py-4 flex flex-row items-center justify-start">
                        View all <i className="pi pi-arrow-right text-theme-purple-1 px-2" />
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="bg-white flex flex-col justify-between rounded-2xl pt-12 px-6 relative">
                  <div className="relative">
                    <img src="/assets/images/board2.png" alt="star" className="z-20 relative" />
                    <div className="absolute -top-4 -left-2 rounded-full p-16 bg-theme-13" />
                  </div>
                  <div className="flex flex-col justify-between items-start pt-8 h-full">
                    <div>
                      <p className="text-lg font-bold">Bridge Advertising</p>
                    </div>
                    <div>
                      <Link to={'/customize'}>
                        <p className="py-4 flex flex-row items-center justify-start">
                          View all <i className="pi pi-arrow-right text-theme-purple-1 px-2" />
                        </p>
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full px-28 pb-16 py-32">
        <div className="flex flex-row justify-between items-center w-full">
          <h5 className="text-3.5xl font-bold">
            Sites you may <span className="text-theme-purple-1">Like</span>
          </h5>
          <Link to="/customize">
            <div className="flex flex-row items-center justify-center w-fit">
              <p className="text-black font-bold text-normal">View All</p>
              <i className="pi pi-arrow-right text-theme-purple-1 px-2 tex-normal" />
            </div>
          </Link>
        </div>
        <div className="grid grid-cols-4 gap-8 py-10">
          {products.slice(products.length - 4).map((product, i) => (
            <div key={i} className="flex flex-col w-full shadow-2xl relative overflow-hidden">
              <div
                className="bg-cover h-48 relative bg-no-repeat bg-center"
                style={{
                  backgroundImage: `url(${product.images[0]})`
                }}
              ></div>
              <div className="flex flex-col px-4 py-4">
                <p className="font-bold text-lg py-1">{product.name}</p>
                <p className="text-theme-gray-3 text-sm py-1 text-justify">{product.description.slice(0, 100)} more....</p>
                <p className="font-bold text-2xl py-4">₹ {product.price}</p>
                <Link to={'/product/' + product.id}>
                  <p className="py-1 flex flex-row items-center font-bold justify-start">
                    View Details <i className="pi pi-arrow-right text-theme-purple-1 px-2" />
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="px-96 flex flex-row items-center">
        <div className="flex flex-row justify-between items-center w-full">
          <img src="/assets/images/peslogo.png" className={"w-20 h-20"} alt="home-1"/>
          <img src="/assets/images/hnsite.jpeg" className={"w-20 h-20"} alt="home-1"/>
        </div>
      </div> */}
      <Testimonial />
      <ContactUs onContactSubmit={onContactSubmit} />
      <Footer />
      <Copyright />
    </div>
  );
};

export default HomeScreen;
