import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';
import callCampaignProductsApi from '../api/campaign/campaignProducts';
import CampaignProductsScreen from '../views/CampaignProducts';
import useCampaignData from '../hooks/campaignData';
import useDates from '../hooks/dates';
import { useCampaignDataContext } from '../contexts/CampaignData';
import { useCartContext } from '../contexts/CartContext';
import checkProductAvailabilityStatus from '../api/checkProductAvailabilityStatus';
import useCart from '../hooks/cart';
import findCampaignType from '../api/campaign/findCampaignType';
import { useAuthContext } from '../contexts/AuthContext';
import callCustomizedCampaignProductsApi from '../api/campaign/callCustomizedCampaignProductsApi';
import useCustomizedCampaign from '../hooks/customizedCampaign';
import saveCampaignProductsApi from '../api/campaign/saveCampaignProducts';
import { useNavigate } from 'react-router-dom';

const CampaignProducts = () => {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const from = searchParams.get('from');
  const [campaign, setCampaign] = useState(null);
  const { campaignData, setCampaignData } = useCampaignDataContext();
  const { getValueOfDate, getMinimumEndDayOfItem, getMinimumEndDay, convertDatesArray } = useDates();
  const [productAvailability, setProductAvailability] = useState([]);
  const [campaignInData, setCampaignInData] = useState(null);
  const [datesOfWholeCampaign, setDatesOfWholeCampaign] = useState({
    startDate: null,
    endDate: null
  });

  const {
    findMaximumToDateInCampaignData,
    findMinimumFromDateInCampaignData,
    findCampaignById,
    getMinimumStartDayForCampaignData,
    getMinimumEndDayForCampaignData,
    calculatePriceOfSingleProductInCampaignData,
    calculateTotalPriceOfCampaignData,
    onDateChangeOfProduct,
    findProductInCampaignById,
    onMultipleDateChange,
    checkDatesOfAllProductsIfSelected,
    onAddToCart
  } = useCampaignData(campaign);

  const { setCustomizedCampaignToDefault } = useCustomizedCampaign();

  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [minStartDate, setMinStartDate] = useState('');
  const [maxEndDate, setMaxEndDate] = useState('');
  const [startDateSelected, setStartDateSelected] = useState(false);
  const { setGlobalCart } = useCartContext();
  const { removeFromCart, checkCampaignIfInCart } = useCart();
  const { token } = useAuthContext();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (campaignId) {
      const tempMinStartDate = findMinimumFromDateInCampaignData(campaignId);
      const tempMaxEndDate = findMaximumToDateInCampaignData(campaignId);
      setMinStartDate(new Date(tempMinStartDate));
      setMaxEndDate(new Date(tempMaxEndDate));
    }
  }, [findMinimumFromDateInCampaignData, findMaximumToDateInCampaignData, campaignId, campaignData]);

  const callFunctionProductAvailability = useCallback(async data => {
    const result = await checkProductAvailabilityStatus(data);
    if (result) {
      setProductAvailability(result.data);
    }
  }, []);

  useEffect(() => {
    const data = campaignData.campaigns.find(item => item.id === parseInt(campaignId));
    if (data && data.products.length > 0) {
      let callApi = true;
      const datesData = data.products.map(item => {
        if (!item.from_date || !item.to_date) {
          callApi = false;
        }
        return {
          product_id: item.product.id,
          from_date: item.from_date,
          to_date: item.to_date
        };
      });
      if (callApi) {
        callFunctionProductAvailability(datesData);
      }
    }
  }, [callFunctionProductAvailability, datesOfWholeCampaign, campaignData, campaignId]);

  const getDataOfASingleCampaign = useCallback(async () => {
    try {
      const campaignType = await findCampaignType(campaignId);
      let result = null;
      if (campaignType.data.data === 'standard') {
        result = await callCampaignProductsApi(campaignId);
      } else if (campaignType.data.data === 'customized') {
        if (token) {
          result = await callCustomizedCampaignProductsApi(campaignId, token);
        }
      }
      if (result.status === 200) {
        setCampaign(result.data);
      } else if (result.status !== 200) {
      }
    } catch (error) {}
  }, [campaignId, token]);

  useEffect(() => {
    getDataOfASingleCampaign();
  }, [getDataOfASingleCampaign]);

  useEffect(() => {
    if (from === 'addToCart' && campaign && !updated) {
      onAddToCart(campaignId);
      setCustomizedCampaignToDefault();
      setCampaignData(prev => ({
        campaigns: [...prev.campaigns, campaign]
      }));
      setUpdated(true);
    }
  }, [campaign, campaignId, from, setCampaignData, setCustomizedCampaignToDefault, updated, token, onAddToCart]);

  useEffect(() => {
    const tempCampaign = findCampaignById(campaignId);
    if (tempCampaign) {
      setCampaignInData(tempCampaign);
    }
  }, [findCampaignById, campaignId, campaignData]);

  const onSaveCampaignClicked = useCallback(() => {
    const { isAllSelected, tempCampaign } = checkDatesOfAllProductsIfSelected(campaignId);
    if (isAllSelected) {
      const data = {
        campaign_id: campaignId,
        products: tempCampaign.products
      };

      if (token) {
        const result = saveCampaignProductsApi(data, token);
        if (result) {
          console.log('🚀 ~ file: CampaignProducts.js ~ line 167 ~ onSaveCampaignClicked ~ result', result);
        }
      }
    }
  }, [campaignId, token, checkDatesOfAllProductsIfSelected]);

  const onClickBuyNow = useCallback(() => {
    const { isAllSelected } = checkDatesOfAllProductsIfSelected(campaignId);
    if (isAllSelected) {
      setGlobalCart([]);
      onAddToCart(campaignId);
      navigate('/user-details');
    }
  }, [onAddToCart, checkDatesOfAllProductsIfSelected, setGlobalCart, navigate, campaignId]);

  return (
    <CampaignProductsScreen
      campaign={campaign}
      onClickBuyNow={onClickBuyNow}
      campaignInData={campaignInData}
      findMaximumToDateInCampaignData={findMaximumToDateInCampaignData}
      findMinimumFromDateInCampaignData={findMinimumFromDateInCampaignData}
      findCampaignById={findCampaignById}
      getMinimumStartDayForCampaignData={getMinimumStartDayForCampaignData}
      getMinimumEndDayForCampaignData={getMinimumEndDayForCampaignData}
      calculatePriceOfSingleProductInCampaignData={calculatePriceOfSingleProductInCampaignData}
      calculateTotalPriceOfCampaignData={calculateTotalPriceOfCampaignData}
      onDateChangeOfProduct={onDateChangeOfProduct}
      findProductInCampaignById={findProductInCampaignById}
      onMultipleDateChange={onMultipleDateChange}
      minStartDate={minStartDate}
      maxEndDate={maxEndDate}
      startDateSelected={startDateSelected}
      setStartDateSelected={setStartDateSelected}
      getValueOfDate={getValueOfDate}
      getMinimumEndDayOfItem={getMinimumEndDayOfItem}
      getMinimumEndDay={getMinimumEndDay}
      convertDatesArray={convertDatesArray}
      onAddToCart={onAddToCart}
      productAvailability={productAvailability}
      setDatesOfWholeCampaign={setDatesOfWholeCampaign}
      removeFromCart={removeFromCart}
      checkCampaignIfInCart={checkCampaignIfInCart}
      onSaveCampaignClicked={onSaveCampaignClicked}
      helpModalVisible={helpModalVisible}
      setHelpModalVisible={setHelpModalVisible}
    />
  );
};

export default CampaignProducts;
