import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

const useAuth = () => {
  const { signupMobile, confirmOtp, setCurrentUser, setIsAuthenticated } = useAuthContext();
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [params] = useSearchParams();
  const [disabled, setDisabled] = useState(true);
  const [otpDisabled, setOtpDisabled] = useState(true);
  const [disableResend, setDisableResend] = useState(true);
  const [successfullyAuthenticated, setSuccessfullyAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(false);
  }, [setIsAuthenticated]);

  useEffect(() => {
    if (mobile.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    return () => {
      setDisabled(true);
    };
  }, [mobile]);

  useEffect(() => {
    if (otp.length === 6) {
      setOtpDisabled(false);
    } else {
      setOtpDisabled(true);
    }
    return () => {
      setOtpDisabled(true);
    };
  }, [otp]);

  const callDisableResendOtp = useCallback(() => {
    setTimeout(() => {
      setDisableResend(false);
    }, 30000);
  }, []);

  const submitPhoneNumber = useCallback(
    async (e, mobile) => {
      e.preventDefault();
      setLoading(true);
      const response = await signupMobile(mobile);
      if (response) {
        callDisableResendOtp();
        setVerificationId(response.verificationId);
        setLoading(false);
      }
    },
    [signupMobile, callDisableResendOtp]
  );

  const submitOtp = useCallback(
    async (e, otp, redirectRequired = true) => {
      e.preventDefault();
      setLoading(true);
      const result = await confirmOtp(otp);
      if (result) {
        setCurrentUser(result);
        setSuccessfullyAuthenticated(true);
      }
      const redirect = params.get('redirect');
      if (!redirectRequired) {
        window.location.reload();
        return;
      }
      if (redirect && redirectRequired) {
        navigator('/' + redirect);
      } else {
        navigator(-1);
      }
    },
    [confirmOtp, navigator, params] // eslint-disable-line
  );

  const callSubmitPhoneNumber = useCallback(
    e => {
      submitPhoneNumber(e, mobile);
    },
    [mobile, submitPhoneNumber]
  );

  const callSetMobile = useCallback(e => {
    setMobile(e.target.value);
  }, []);

  const callSubmitOtp = useCallback(
    (e, redirectRequired = true) => {
      submitOtp(e, otp, redirectRequired);
    },
    [otp, submitOtp]
  );

  return {
    setOtp,
    callSubmitPhoneNumber,
    callSetMobile,
    otp,
    otpDisabled,
    mobile,
    disabled,
    callSubmitOtp,
    disableResend,
    loading,
    submitOtp,
    successfullyAuthenticated,
    verificationId
  };
};
export default useAuth;
