import { memo } from 'react';
import HomeNavbar from '../components/Navbars/HomeNavbar';
import CalendarTemplate from '../components/Calendar/CalendarTemplate';

const MyOrdersScreen = memo(
  ({
    setCurrentTab,
    currentTab,
    findMinimumFromDateInCampaign,
    findMaximumToDateInCampaign,
    savedCampaigns,
    bookingCampaigns,
    calculateTotalPriceOfCampaign
  }) => {
    return (
      <div className="w-full max-h-screen font-theme-2">
        <HomeNavbar />
        <div className="flex flex-col items-center justify-center py-24">
          <h1 className="text-3xl font-bold text-center py-4">My Orders</h1>
          <div className="grid grid-cols-1/3 gap-8 py-4 w-full">
            <div className="grid grid-col-1 gap-y-4 px-20 h-fit">
              <div
                className="flex flex-row items-center gap-x-10 justify-between text-theme-purple-1 p-2 rounded"
                onClick={() => setCurrentTab('saved')}
              >
                <div>Saved Campaigns</div>
                <div>{'>'}</div>
              </div>
              <div
                className="flex flex-row items-center gap-x-10 justify-between bg-theme-gray-5 p-2 rounded"
                onClick={() => setCurrentTab('upcoming')}
              >
                <div>Upcoming Campaigns</div>
                <div>{'>'}</div>
              </div>
              <div
                className="flex flex-row items-center gap-x-10 justify-between bg-theme-gray-5 p-2 rounded"
                onClick={() => setCurrentTab('ongoing')}
              >
                <div>Ongoing Campaigns</div>
                <div>{'>'}</div>
              </div>
              <div
                className="flex flex-row items-center gap-x-10 justify-between bg-theme-gray-5 p-2 rounded"
                onClick={() => setCurrentTab('completed')}
              >
                <div>Completed Campaigns</div>
                <div>{'>'}</div>
              </div>
            </div>
            <div className="pr-20 flex flex-col">
              <h2 className="text-2xl font-bold py-4">
                {(() => {
                  switch (currentTab) {
                    case 'saved':
                      return 'Saved Campaigns';
                    case 'upcoming':
                      return 'Upcoming Campaigns';
                    case 'ongoing':
                      return 'Ongoing Campaigns';
                    case 'completed':
                      return 'Completed Campaigns';
                    default:
                      return 'Saved Campaigns';
                  }
                })()}
              </h2>
              <div className="grid grid-cols-1 gap-y-4">
                {(currentTab === 'saved'
                  ? savedCampaigns
                  : (() => {
                      switch (currentTab) {
                        case 'upcoming':
                          return bookingCampaigns?.upcoming_campaigns;
                        case 'ongoing':
                          return bookingCampaigns?.ongoing_campaigns;
                        case 'completed':
                          return bookingCampaigns?.completed_campaigns;
                        default:
                          return [];
                      }
                    })()
                )?.map((item, i) => (
                  <div className="w-full h-fit shadow-xl p-6">
                    <div className="grid grid-cols-1/4 w-full gap-4">
                      <div className="flex flex-col items-start justify-center w-full h-full">
                        <img src="/assets/images/card/card1.png" className="h-full" alt="place" />
                      </div>
                      <div className="grid grid-rows-1 w-full h-fit gap-4">
                        <div className="grid grid-cols-1/3 gap-20 w-full">
                          <div className="grid grid-flow-row gap-2 w-full">
                            <h4 className="text-lg font-bold text-theme-night-black-2">
                              {currentTab === 'saved' ? item.campaign_details.name : item.campaign.name}
                            </h4>
                            <p className="text-sm text-theme-gray-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <p className="text-lg font-bold text-theme-night-black-2">₹ {calculateTotalPriceOfCampaign(item)}</p>
                          </div>
                          <div className="grid grid-cols-2 gap-4 h-fit">
                            <div className="grid grid-flow-row h-fit gap-1.5">
                              <div>
                                <p className="text-sm text-theme-gray-4">Dimenssion</p>
                              </div>
                              <div>
                                <p className="text-normal text-theme-night-black-2">L x W x H</p>
                              </div>
                            </div>
                            <div className="grid grid-flow-row h-fit gap-1.5">
                              <div>
                                <p className="text-sm text-theme-gray-4">Dimenssion</p>
                              </div>
                              <div>
                                <p className="text-normal text-theme-night-black-2">L x W x H</p>
                              </div>
                            </div>
                            <div className="grid grid-flow-row h-fit gap-1.5">
                              <div>
                                <p className="text-sm text-theme-gray-4">Dimenssion</p>
                              </div>
                              <div>
                                <p className="text-normal text-theme-night-black-2">L x W x H</p>
                              </div>
                            </div>
                            <div className="grid grid-flow-row h-fit gap-1.5">
                              <div>
                                <p className="text-sm text-theme-gray-4">Dimenssion</p>
                              </div>
                              <div>
                                <p className="text-normal text-theme-night-black-2">L x W x H</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-flow-col gap-2 items-center justify-end w-full py-2">
                      <CalendarTemplate value={new Date(findMinimumFromDateInCampaign(item))} disabled />
                      <CalendarTemplate value={new Date(findMaximumToDateInCampaign(item))} disabled />
                      {currentTab === 'saved' && (
                        <>
                          <button className="border-theme-purple-1 border text-theme-purple-1  py-2 px-4 rounded">Add to Cart</button>
                          <button className="bg-theme-purple-1 text-white py-2 px-4 rounded">Buy Now</button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default MyOrdersScreen;
