import { useState } from 'react';
import { Image } from 'primereact/image';


const DisplayImages = ({images}) => {
  const [backgroundImage, setBackgroundImage] = useState(images[0]);

  return (
    <div className="w-full">
      <div className="w-full flex flex-row items-center justify-center">
        <div className="h-[425px] w-full max-w-2xl flex flex-col items-center justify-center">
          <Image preview src={backgroundImage} alt="product" className="w-full h-full" imageClassName="w-full h-full" />
        </div>
      </div>
      <div className="max-h-96 flex flex-row items-center justify-center my-4 w-full">
        <div className="w-full max-w-2xl overflow-x-scroll scrollbar-hide">
          <div className="grid grid-flow-col gap-6 w-full">
            {images.map((image, i) => (
              <div
                className="h-32 bg-theme-6 w-32 bg-center bg-cover bg-no-repeat"
                onClick={() => setBackgroundImage(image)}
                key={i}
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayImages;
