import Footer from '../components/Footer';
import HomeNavbar from '../components/Navbars/HomeNavbar';
import { memo } from 'react';

const PrivacyPolicy = memo(() => {
  return (
    <>
      <div className="w-full bg-slate-100 py-20 px-16 text-justify font-theme-2">
        <HomeNavbar />
        <h2 className="text-5xl font-bold py-6">Privacy Policy</h2>
        <p className="py-2">Last updated: February 15, 2022</p>
        <p className="py-2">
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service
          and tells You about Your privacy rights and how the law protects You.
        </p>
        <p className="py-2">
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy is maintained by the{' '}
          <a href="https://www.privacypolicygenerator.info">Privacy Policy Generator</a> and You may consult the most current version of this Privacy
          Policy at any time at this page.
        </p>
        <p className="py-2">
          The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at{' '}
          <a href="https://www.adarth.in">www.adarth.in</a>, unless otherwise defined in this Privacy Policy.
        </p>
        <h2 className="text-5xl font-bold py-6">Interpretations and Definitions</h2>
        <h3 className="text-4xl font-bold py-4">Interpretation</h3>
        <p className="py-2">
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall
          have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        <h3 className="text-4xl font-bold py-4">Definitions</h3>
        <p className="py-2">For the purposes of this Privacy Policy:</p>
        <ul className="py-2 list-inside list-disc">
          <li className="py-2">
            <span className="font-bold">Account</span> means a unique account created for You to access our Service or parts of our Service.
          </li>
          <li className="py-2">
            <span className="font-bold">Company</span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Adarth
            Private Limited, 208, 2ND FLOOR, DLF STAR TOWER, SECTOR - 30 GURGAON Gurgaon HR 122001 IN.
          </li>
          <li className="py-2">
            <span className="font-bold">Cookies</span> means small files that are placed on Your computer, mobile device or any other device by a
            website, containing the details of Your browsing history on that website among its many uses.
          </li>
          <li className="py-2">
            <span className="font-bold">Country</span> refers to: Haryana, India
          </li>
          <li className="py-2">
            <span className="font-bold">Device</span> means any device that can access the Service such as a computer, a cellphone or a digital
            tablet.
          </li>
          <li className="py-2">
            <span className="font-bold">Personal Data</span> is any information that relates to an identified or identifiable individual.
          </li>
          <li className="py-2">
            <span className="font-bold">Service</span> refers to the Website.
          </li>
          <li className="py-2">
            <span className="font-bold">Usage Data</span> refers to data collected automatically, either generated by the use of the Service or from
            the Service infrastructure itself (for example, the duration of a page visit).
          </li>
          <li className="py-2">
            <span className="font-bold">Website</span> refers to Adarth, accessible from <a href="https://www.adarth.in">www.adarth.in</a>
          </li>
          <li className="py-2">
            <span className="font-bold">You</span> means the individual accessing or using the Service, or the company, or other legal entity on
            behalf of which such individual is accessing or using the Service, as applicable.
          </li>
        </ul>
        <h2 className="text-5xl font-bold py-6">Collecting and Using Your Personal Data</h2>
        <h3 className="text-4xl font-bold py-4">Types of Data Collected</h3>
        <h4 className="text-3xl font-bold py-4">Personal Data</h4>
        <p className="py-2">
          While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is not limited to:
        </p>
        <ul className="py-2 list-inside list-disc">
          <li className="py-2">Email address</li>
          <li className="py-2">First name and last name</li>
          <li className="py-2">Phone number</li>
          <li className="py-2">Address, State, Province, ZIP/Postal code, City</li>
          <li className="py-2">Cookies and Usage Data</li>
        </ul>
        <h4 className="text-3xl font-bold py-4">Usage Data</h4>
        <p className="py-2">
          We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as Your
          computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and
          date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
        </p>
        <h4 className="text-3xl font-bold py-4">Tracking & Cookies Data</h4>
        <p className="py-2">
          We use cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. You can instruct Your browser
          to refuse all cookies or to indicate when a cookie is being sent. However, if You do not accept cookies, You may not be able to use some
          parts of our Service.
        </p>
        <ul className="py-2 list-inside list-disc">
          <li className="py-2">
            <span className="font-bold">Cookies and Browser Cookies.</span> A cookie is a small file placed on Your Device. You can instruct Your
            browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to
            use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <span className="font-bold">Flash Cookies.</span>
            Certain features of our Service may use local stored objects (or Flash cookies) to collect and store information about Your preferences or
            Your activity on our Service. Flash cookies are managed by the same browser settings as those used by Browser Cookies. For more
            information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared
            objects?" available at{' '}
            <a
              className="text-blue-500 underline"
              href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
            >
              https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
            </a>
          </li>
          <li className="py-2">
            <span className="font-bold">Web Beacons. </span>
            Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs,
            pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email
            and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server
            integrity).
          </li>
        </ul>
        <p className="py-2">
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: Use of Cookies by Free Privacy Policy.
        </p>
        <p className="py-2">We use both session and persistent Cookies for the purposes set out below:</p>
        <ul className="py-2 list-inside list-disc">
          <li className="py-2">
            <span className="font-bold">Necessary / Essential Cookies</span>
            <p className="py-2 pl-5">Type: Session Cookies</p>
            <p className="py-2 pl-5">Administered by: Us</p>
            <p className="py-2 pl-5">
              These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features.
              They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for
              cannot be provided, and We only use these Cookies to provide You with those services.
            </p>
          </li>
          <li className="py-2">
            <span className="font-bold">Cookies Policy / Notice Acceptance Cookies</span>
            <p className="py-2 pl-5">Type: Persistent Cookies</p>
            <p className="py-2 pl-5">Administered by: Us</p>
            <p className="py-2 pl-5">These Cookies identify if users have accepted the use of cookies on the Website.</p>
          </li>
          <li className="py-2">
            <span className="font-bold">Functionality Cookies</span>
            <p className="py-2 pl-5">Type: Persistent Cookies</p>
            <p className="py-2 pl-5">Administered by: Us</p>
            <p className="py-2 pl-5">
              These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language
              preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your
              preferences every time You use the Website.
            </p>
          </li>
        </ul>
        <p className="py-2">For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.</p>
        <h4 className="text-3xl font-bold py-4">Use of Your Personal Data</h4>
        <p className="py-2">The Company may use Personal Data for the following purposes:</p>
        <ul className="py-2 list-inside list-disc">
          <li className="py-2">
            <span className="font-bold">To provide and maintain our Service </span>
            including to monitor the usage of our Service, and to analyze how our Service is used.
          </li>
          <li className="py-2">
            <span className="font-bold">To manage Your Account: </span>
            to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of
            the Service that are available to You as a registered user.
          </li>
          <li className="py-2">
            <span className="font-bold">For the performance of a contract: </span>
            The development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any
            other contract with Us through the Service.
          </li>
          <li className="py-2">
            <span className="font-bold">To contact You: </span>
            To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push
            notifications regarding updates or informative communications related to the functionalities, products or contracted services, including
            the security updates, when necessary or reasonable for their implementation.
          </li>
          <li className="py-2">
            <span className="font-bold">To provide You</span> with news, special offers and general information about other goods, services and events
            which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such
            information.
          </li>
          <li className="py-2">
            <span className="font-bold">To manage Your requests: </span>
            To attend and manage Your requests to Us.
          </li>
          <li className="py-2">
            <span className="font-bold">For business transfers: </span>
            We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
            Personal Data held by Us about our Service users is among the assets transferred.
          </li>
          <li className="py-2">
            <span className="font-bold">For other purposes: </span>
            We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our
            promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
          </li>
        </ul>
        <p className="py-2">We may share Your personal information in the following situations:</p>
        <ul className="py-2 list-inside list-disc">
          <li className="py-2">
            <span className="font-bold">With Service Providers: </span>
            We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to detect, prevent and
            address technical issues, to gather additional information about You that is not provided by You, to provide You with customer support, to
            provide You with technical information, to monitor the performance of our Service, to send You marketing and promotional information, to
            contact You, and to facilitate payment for Our products and services.
          </li>
          <li className="py-2">
            <span className="font-bold">For Business Transfers: </span>
            We may share or transfer Your information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or
            acquisition of all or a portion of our business to another company.
          </li>
          <li className="py-2">
            <span className="font-bold">With Affiliates: </span>
            We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates
            include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common
            control with Us and whose owners consent to be bound by the terms of this Privacy Policy.
          </li>
          <li className="py-2">
            <span className="font-bold">With Business Partners: </span>
            We may share Your information with Our business partners to offer You certain products, services or promotions.
          </li>
          <li className="py-2">
            <span className="font-bold">With other users: </span>
            when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside the user base.
          </li>
          <li className="py-2">
            <span className="font-bold">With Your consent: </span>
            We may disclose Your personal information for any other purpose with Your consent.{' '}
          </li>
        </ul>
        <h4 className="text-3xl font-bold py-4">Retention of Your Personal Data</h4>
        <p className="py-2">
          The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain
          and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data
          to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
        </p>
        <p className="py-2">
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time,
          except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to
          retain this data for longer time periods.
        </p>
        <h4 className="text-3xl font-bold py-4">Transfer of Your Personal Data</h4>
        <p className="py-2">
          Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your
          jurisdiction
        </p>
        <p className="py-2">
          Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
        </p>
        <p className="py-2">
          The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy
          and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including
          the security of Your data and other personal information.
        </p>
        <h3 className="text-4xl font-bold py-4">Disclosure of Your Personal Data</h3>
        <h4 className="text-3xl font-bold py-4">Business Transactions</h4>
        <p className="py-2">
          If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your
          Personal Data is transferred and becomes subject to a different Privacy Policy.
        </p>
        <h4 className="text-3xl font-bold py-4">Law enforcement</h4>
        <p className="py-2">
          Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h4 className="text-3xl font-bold py-4">Other legal requirements</h4>
        <p className="py-2">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul className="py-2 list-inside list-disc">
          <li className="py-2">Comply with a legal obligation</li>
          <li className="py-2">Protect and defend the rights or property of the Company</li>
          <li className="py-2">Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li className="py-2">Protect the personal safety of Users of the Service or the public</li>
          <li className="py-2">Protect against legal liability</li>
        </ul>
        <h4 className="text-3xl font-bold py-4">Security of Your Personal Data</h4>
        <p className="py-2">
          The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <h3 className="text-4xl font-bold py-4">Children's Privacy</h3>
        <p className="py-2">
          Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under
          the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If
          We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps
          to remove that information from Our servers.
        </p>
        <p className="py-2">
          If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that information.
        </p>
        <h3 className="text-4xl font-bold py-4">Links to Other Websites</h3>
        <p className="py-2">
          Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
        </p>
        <p className="py-2">
          We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
        </p>
        <h3 className="text-4xl font-bold py-4">Changes to this Privacy Policy</h3>
        <p className="py-2">
          We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
        </p>
        <p className="py-2">
          We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p className="py-2">
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h3 className="text-4xl font-bold py-4">Contact Us</h3>
        <p className="py-2">If you have any questions about this Privacy Policy, You can contact us:</p>
        <ul className="py-2 list-inside list-disc">
          <li className="py-2">
            By email:
            <a href="mailto:info@adarth.in">info@adarth.in</a>
          </li>
          <li className="py-2">
            By phone number:
            <a href="tel:+918431153056">+91 8431153056</a>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
});

export default PrivacyPolicy;
