import { useCustomizedCampaignContext } from '../contexts/CustomizedCampaignContext';
import useCustomizedCampaign from '../hooks/customizedCampaign';
import CustomizedCampaignPreviewScreen from '../views/CustomizedCampaignPreview';
import { useState, memo, useCallback } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { useEffect } from 'react';
import saveCustomizedCampaign from '../api/campaign/saveCustomizedCampaign';
import useDates from '../hooks/dates';
import { useNavigate } from 'react-router-dom';
import useCart from '../hooks/cart';
import { useCampaignDataContext } from '../contexts/CampaignData';
import useCampaignData from '../hooks/campaignData';
import checkProductAvailabilityStatus from '../api/checkProductAvailabilityStatus';
import callHelpApi from '../api/callHelp';

const CustomizedCampaignPreview = memo(() => {
  const { customizedCampaign, setCustomizedCampaign } = useCustomizedCampaignContext();
  const { token } = useAuthContext();
  const [productAvailability, setProductAvailability] = useState([]);

  const {
    changeCustomizedCampaignName,
    toggleChangeName,
    nameDisabled,
    calculateTotalPriceOfCustomizedCampaign,
    getMinimumStartDayForCustomizedCampaign,
    getMinimumEndDayForCustomizedCampaign,
    findMinimumFromDateInCustomizedCampaign,
    findMaximumToDateInCustomizedCampaign,
    onDateChangeOfWholeCampaign,
    getProductIfInCustomizedCampaign,
    removeProductFromCustomizedCampaign,
    calculatePriceOfSingleProductInCustomizedCampaign,
    onDateChange
  } = useCustomizedCampaign();

  const [startDateSelected, setStartDateSelected] = useState(false);
  const { setCampaignData } = useCampaignDataContext();
  const { findCampaignById } = useCampaignData();
  const navigate = useNavigate();
  const [helpModal, setHelpModal] = useState(false);
  const [minStartDate, setMinStartDate] = useState('');
  const [maxEndDate, setMaxEndDate] = useState('');
  const { getValueOfDate, getMinimumEndDayOfItem, getMinimumEndDay, convertDatesArray } = useDates();
  const { checkCampaignIfInCart, removeFromCart } = useCart();
  const [images, setImages] = useState([])

  useEffect(() => {
    if (customizedCampaign.products.length === 0) {
      alert('Please add at least one product to your customized campaign.');
      navigate('/customize');
    }
  }, [customizedCampaign, navigate]);

  useEffect(() => {
    const tempMinStartDate = findMinimumFromDateInCustomizedCampaign();
    const tempMaxEndDate = findMaximumToDateInCustomizedCampaign();
    setMinStartDate(new Date(tempMinStartDate));
    setMaxEndDate(new Date(tempMaxEndDate));
  }, [customizedCampaign, findMinimumFromDateInCustomizedCampaign, findMaximumToDateInCustomizedCampaign]);

  const callFunctionProductAvailability = useCallback(async data => {
    const result = await checkProductAvailabilityStatus(data);
    if (result) {
      setProductAvailability(result.data);
    }
  }, []);

  useEffect(() => {
    if (customizedCampaign.products.length > 0) {
      let images = []
      const data = customizedCampaign.products.map(item => {
        images = images.concat(item.product.images)
        return {
          from_date: item.from_date,
          to_date: item.to_date,
          product_id: item.product.id
        };
      })
      setImages(images)
      callFunctionProductAvailability(data);
    }
  }, [customizedCampaign.products, callFunctionProductAvailability]);

  const onSaveCustomizedCampaign = useCallback(
    async (from = null) => {
      if (!token) {
        navigate('/verify-mobile');
      }
      const result = await saveCustomizedCampaign(customizedCampaign, token);

      if (result) {
        const campaign_id = result.data.data.id;
        const campaign = findCampaignById(campaign_id);

        if (campaign) {
          setCampaignData(prevState => ({
            ...prevState,
            campaigns: prevState.campaigns.filter(item => item.id !== campaign_id)
          }));
        }

        setCampaignData(prevState => ({
          ...prevState,
          campaigns: [
            ...prevState.campaigns,
            {
              ...customizedCampaign,
              id: campaign_id
            }
          ]
        }));

        navigate('/campaign/' + campaign_id + '?from=' + from);

        setCustomizedCampaign({
          name: 'Untitled Campaign',
          products: []
        });
      }
    },
    [customizedCampaign, token, navigate, setCampaignData, findCampaignById, setCustomizedCampaign]
  );

  const onAddToCart = useCallback(() => {
    onSaveCustomizedCampaign('addToCart');
  }, [onSaveCustomizedCampaign]);

  const helpFormSubmit = e => {
    const data = {
      phone_number: e.target.phone_number,
      email: e.target.email,
      message: e.target.message,
      is_customized: true
    };

    callHelpApi(data);
  };

  return (
    <CustomizedCampaignPreviewScreen
      getMinimumStartDayForCustomizedCampaign={getMinimumStartDayForCustomizedCampaign}
      customizedCampaign={customizedCampaign}
      changeCustomizedCampaignName={changeCustomizedCampaignName}
      nameDisabled={nameDisabled}
      toggleChangeName={toggleChangeName}
      onDateChangeOfWholeCampaign={onDateChangeOfWholeCampaign}
      minStartDate={minStartDate}
      maxEndDate={maxEndDate}
      getMinimumEndDayForCustomizedCampaign={getMinimumEndDayForCustomizedCampaign}
      calculateTotalPriceOfCustomizedCampaign={calculateTotalPriceOfCustomizedCampaign}
      startDateSelected={startDateSelected}
      setStartDateSelected={setStartDateSelected}
      getProductIfInCustomizedCampaign={getProductIfInCustomizedCampaign}
      removeProductFromCustomizedCampaign={removeProductFromCustomizedCampaign}
      calculatePriceOfSingleProductInCustomizedCampaign={calculatePriceOfSingleProductInCustomizedCampaign}
      onSaveCustomizedCampaign={onSaveCustomizedCampaign}
      onDateChange={onDateChange}
      getValueOfDate={getValueOfDate}
      getMinimumEndDayOfItem={getMinimumEndDayOfItem}
      getMinimumEndDay={getMinimumEndDay}
      convertDatesArray={convertDatesArray}
      onAddToCart={onAddToCart}
      checkCampaignIfInCart={checkCampaignIfInCart}
      removeFromCart={removeFromCart}
      productAvailability={productAvailability}
      helpModal={helpModal}
      setHelpModal={setHelpModal}
      helpFormSubmit={helpFormSubmit}
      images={images}
    />
  );
});

export default CustomizedCampaignPreview;
