import { memo } from 'react';

const SingleItem = memo(
  ({
    switchToSingleProduct,
    addProductInCustomizedCampaign,
    removeProductFromCustomizedCampaign,
    checkIfProductIsInCustomizedCampaign,
    product,
    fromDate = null,
    toDate = null,
    keyId,
    productAvailability = null,
    toBeShownAvailability = false
  }) => {
    return (
      <div className="flex flex-col relative bg-white h-full shadow-2xl drop-shadow-2xl font-theme-2">
        <div
          className="h-48 flex flex-row items-center justify-center bg-cover bg-no-repeat bg-center w-full relative"
          style={{
            backgroundImage: `url(${product.images[0]})`
          }}
        ></div>
        <div className="flex flex-col p-4 items-start justify-start">
          {toBeShownAvailability && fromDate && toDate && productAvailability.length > 0 ? (
            <div
              className={`rounded-xl py-1.5 px-2 ${
                productAvailability[keyId]?.status ? 'bg-theme-light-green-1 text-theme-dark-green-1' : 'bg-theme-light-red-1 text-theme-dark-red-1'
              }  tracking-widest uppercase  font-bold text-xs`}
            >
              {productAvailability[keyId]?.status ? 'Available' : 'Unavailable'}
            </div>
          ) : null}
          <h4 onClick={() => switchToSingleProduct(product)} className="py-1 font-bold">
            {product.name}
          </h4>
          <p className="text-sm py-1 text-theme-gray-3 w-4/5">M G Road TOI Building Towards Brigade Road</p>
          <p className="text-xs py-1">{product.address}</p>
          <div className="flex flex-row items-center justify-between w-full">
            <p className="text-lg py-2 font-bold">₹ {product.price}</p>
            <button
              onClick={
                checkIfProductIsInCustomizedCampaign(product.id)
                  ? () => removeProductFromCustomizedCampaign(product)
                  : () => addProductInCustomizedCampaign(product)
              }
              className={`bg-transparent font-bold py-2 px-6 rounded-lg focus-within:outline-none border-2 ${
                !checkIfProductIsInCustomizedCampaign(product.id)
                  ? 'border-theme-purple-1 text-theme-purple-1'
                  : 'border-theme-cherry-red-1 text-theme-cherry-red-1'
              }`}
            >
              {checkIfProductIsInCustomizedCampaign(product.id) ? 'Remove' : 'Add'}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default SingleItem;
