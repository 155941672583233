import { useCallback, useEffect, useState } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import ProfileScreen from '../views/Profile';
import getUser from '../api/user/get';
import { useNavigate } from 'react-router-dom';
import updateUserByPhoneApi from '../api/user/updateUserByPhone';

const Profile = () => {
  const [active, setActive] = useState('Basic Details');
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const { token } = useAuthContext();

  const getUserData = useCallback(async () => {
    console.log('🚀 ~ file: Profile.js ~ line 17 ~ getUserData ~ token', token);
    if (token) {
      const user = await getUser(token);
      if (user.status === 200) {
        console.log('🚀 ~ file: Profile.js ~ line 21 ~ getUserData ~ user.data.data', user.data);
        setUserData(user.data[0]);
      } else if (user.status === 401) {
        navigate('/verify-mobile?redirect=/profile');
      }
    } else {
      navigate('/verify-mobile?redirect=/profile');
    }
  }, [token, navigate]);

  const updateUserData = useCallback(async () => {
    const result = await updateUserByPhoneApi(userData, token);
    if (result.status === 200) {
      console.log('🚀 ~ file: Profile.js ~ line 36 ~ updateUserData ~ result', result);
    }
  }, [userData, token]);

  useEffect(() => {
    if (token) {
      getUserData();
    }
  }, [getUserData, token]);

  return (
    <ProfileScreen
      active={active}
      setActive={setActive}
      inputsDisabled={inputsDisabled}
      setInputsDisabled={setInputsDisabled}
      userData={userData}
      setUserData={setUserData}
      updateUserData={updateUserData}
    />
  );
};

export default Profile;
