import { createContext, useContext, useEffect, useState } from 'react';

const CartContext = createContext();

const useCartContext = () => {
  return useContext(CartContext);
};

const CartProvider = ({ children }) => {
  const [globalCart, setGlobalCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(globalCart));
  }, [globalCart]);

  return <CartContext.Provider value={{ globalCart, setGlobalCart }}>{children}</CartContext.Provider>;
};

export { CartProvider, useCartContext };
