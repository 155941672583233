import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="flex flex-col w-full px-20 py-16 font-theme-2 bg-theme-night-black-2">
      <div className="grid grid-cols-5 gap-16 py-10">
        <div className="flex flex-col items-center w-full">
          <img src="/assets/images/logo/orange-white-logo.png" alt="Logo, Adarth" className="z-20 relative" />
        </div>
        <div className="flex flex-col w-full">
          <h6 className="text-lg font-bold pb-4 text-theme-11">Outdoor Media</h6>
          <p className="text-normal py-2 text-white">Billboard Advertising</p>
          <p className="text-normal py-2 text-white">Lamp Post Advertising</p>
          <p className="text-normal py-2 text-white">Bridge Advertising</p>
          <p className="text-normal py-2 text-white">Transit Advertising</p>
          <p className="text-normal py-2 text-white">Retail Advertising</p>
        </div>
        <div className="flex flex-col w-full">
          <h6 className="text-lg font-bold pb-4 text-theme-11">Our Company</h6>
          <p className="text-normal py-2 text-white">About Us</p>
          <p className="text-normal py-2 text-white">Our Blog</p>
          <Link to="/terms-and-conditions">
            <p className="text-normal py-2 text-white">Terms and Conditions</p>
          </Link>
          <p className="text-normal py-2 text-white">Contact Us</p>
        </div>
        <div className="flex flex-col w-full">
          <h6 className="text-lg font-bold pb-4 text-theme-11">Need Help</h6>
          <p className="text-normal py-2 text-white">How to Book</p>
          <Link to="/privacy-policy">
            <p className="text-normal py-2 text-white">Privacy Policy</p>
          </Link>
          <p className="text-normal py-2 text-white">Cancellation and Refund</p>
          <p className="text-normal py-2 text-white">Feedback</p>
          <p className="text-normal py-2 text-white">Track My Ad</p>
        </div>
        <div className="flex flex-col w-full">
          <h6 className="text-lg font-bold pb-4 text-theme-11">Follow us</h6>
          <div className="flex flex-row items-center justify-start w-full">
            <i className="pi pi-facebook text-white text-2xl mr-4"></i>
            <i className="pi pi-twitter text-white text-2xl mr-4"></i>
            <i className="pi pi-instagram text-white text-2xl mr-4"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
