import { useCallback, memo } from 'react';
import { useCartContext } from '../contexts/CartContext';
import CartScreen from '../views/Cart';
import { useNavigate } from 'react-router-dom';
import validateBookingDates from '../api/validateBookingDates';
import useCampaignData from '../hooks/campaignData';
import useCustomizedCampaign from '../hooks/customizedCampaign';
import useCart from '../hooks/cart';

const Cart = memo(() => {
  const navigate = useNavigate();
  const { globalCart } = useCartContext();
  const { calculateTotalPriceOfCampaignData, findMaximumToDateInCampaignData, findMinimumFromDateInCampaignData } = useCampaignData();
  const { findMinimumFromDateInCustomizedCampaign, findMaximumToDateInCustomizedCampaign, calculateTotalPriceOfCustomizedCampaign } =
    useCustomizedCampaign();

  const { totalPrice, getBookingDurationArray, removeFromCart } = useCart();

  const differenceBetweenTwoDatesOfCampaign = useCallback(
    campaign => {
      const tempMinFromDate =
        campaign.type === 'customized' ? findMinimumFromDateInCustomizedCampaign() : findMinimumFromDateInCampaignData(campaign.id);
      const tempMaxToDate = campaign.type === 'customized' ? findMaximumToDateInCustomizedCampaign() : findMaximumToDateInCampaignData(campaign.id);
      const minFromDate = new Date(tempMinFromDate);
      const maxToDate = new Date(tempMaxToDate);
      const tempDifferenceBetweenTwoDates = (maxToDate - minFromDate) / (1000 * 60 * 60 * 24);
      return tempDifferenceBetweenTwoDates;
    },
    [
      findMinimumFromDateInCampaignData,
      findMaximumToDateInCampaignData,
      findMinimumFromDateInCustomizedCampaign,
      findMaximumToDateInCustomizedCampaign
    ]
  );

  const verifyBookingDates = useCallback(
    async e => {
      e.preventDefault();
      const result = await validateBookingDates(getBookingDurationArray());
      if (result) {
        if (result.data.status) {
          navigate('/user-details?from=cart');
        }
      }
    },
    [navigate, getBookingDurationArray]
  );

  return (
    <CartScreen
      differenceBetweenTwoDatesOfCampaign={differenceBetweenTwoDatesOfCampaign}
      cart={globalCart}
      calculateTotalPriceOfCampaignData={calculateTotalPriceOfCampaignData}
      calculateTotalPriceOfCustomizedCampaign={calculateTotalPriceOfCustomizedCampaign}
      verifyBookingDates={verifyBookingDates}
      totalPrice={totalPrice}
      removeFromCart={removeFromCart}
    />
  );
});

export default Cart;
