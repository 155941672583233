import HomeScreen from '../views/Home';
import {useRef, useCallback, useState, useEffect} from 'react';
import callContactApi from '../api/callContact';
import getAllCampaigns from "../api/campaign/getAllCampaigns";
import getProductsApi from "../api/products/getProducts";

const Home = () => {
  const scrollRef = useRef(null);
  const [campaigns, setCampaigns] = useState([]);
  const [products, setProducts] = useState([])

  const scrollClick = useCallback(() => {
    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }, [scrollRef]);

  const onContactSubmit = useCallback(async e => {
    e.preventDefault();
    const form = e.target;
    const data = {
      name: form.name.value,
      email: form.email.value,
      phone_number: form.phone_number.value,
      message: form.message.value,
      company_name: form.company_name.value
    };

    const result = await callContactApi(data);

    if (result) {
      alert('Thank you for contacting us. We will get back to you soon.');
    }

    form.reset();
  }, []);

  const getCampaignsData = async () => {
    const campaigns = await getAllCampaigns();
    if (campaigns.status === 200) {
      setCampaigns(campaigns.data.payload.results);
    }
  };

  const getAllProducts = useCallback(async () => {
    const response = await getProductsApi();
    if (response) {
      console.log('🚀 ~ file: Products.js ~ line 11 ~ getAllProducts ~ response', response);
      setProducts(response.data.payload.results);
    }
  }, []);

  useEffect(() => {
    getAllProducts();
    return () => {
      setProducts([]);
    };
  }, [getAllProducts]);

  useEffect(() => {
    getCampaignsData();
  }, []);

  return <HomeScreen scrollRef={scrollRef} scrollClick={scrollClick} onContactSubmit={onContactSubmit} campaigns={campaigns} products={products} />;
};

export default Home;
