import { memo } from 'react';
import { responsive } from '../../constants/responsive/MultiCarouselProducts';
import MultiCarousel from '../MultiCarousel';
import CardTemplate from './CardTemplate';

const ProductsMultiCarousel = memo(() => {
  return (
    <MultiCarousel responsive={responsive}>
      {[1, 2, 3, 4, 5].map(i => (
        <CardTemplate className="px-4" key={i} />
      ))}
    </MultiCarousel>
  );
});

export default ProductsMultiCarousel;
