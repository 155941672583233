import { memo } from 'react';

const CustomizeCampaignNavbar = memo(({ theme, onClick }) => {
  return (
    <button
      className={`${
        theme === 'orange' ? 'bg-theme-orange-1' : 'bg-theme-purple-1'
      } mr-4 font-bold py-2 px-6 rounded-lg focus-within:outline-none text-white`}
      onClick={onClick ? onClick : () => {}}
    >
      Customize Campaign
    </button>
  );
});

export default CustomizeCampaignNavbar;
