import { memo } from 'react';
import ProductsMultiCarousel from './MultiCarousel';

const CampaignsMain = memo(({ type }) => (
  <div className="pt-4 pb-8">
    <div className="flex flex-row py-4 items-between justify-between">
      <h3 className="text-3.5xl font-bold">{type}</h3>
      <p className="text-sm pt-2 underline">View More</p>
    </div>
    <div className="relative">
      <ProductsMultiCarousel />
    </div>
  </div>
));

export default CampaignsMain;
