const SingleCampaignItem = ({ campaign, switchToCampaign }) => {
  return (
    <div className="flex flex-col relative bg-white h-full shadow-2xl drop-shadow-2xl font-theme-2">
      <div
        className="h-48 flex flex-row items-center justify-center bg-cover bg-no-repeat bg-center w-full relative"
        style={{
          backgroundImage: `url(/assets/images/card/card${Math.floor(Math.random() * 4) + 1}.png)`
        }}
      ></div>
      <div className="flex flex-col p-4 items-start justify-start">
        <h4 onClick={() => switchToCampaign(campaign.id)} className="py-1 text-lg font-bold">
          {campaign.name}
        </h4>
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="flex flex-col">
            <p className="text-sm pt-2 text-theme-gray-4">No. of Products</p>
            <p className="text-lg py-0.5">{campaign.total_locations}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-sm pt-2 text-theme-gray-4">Impressions</p>
            <p className="text-lg py-0.5">{campaign.impressions}</p>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between w-full py-2">
          <p className="py-2 font-bold text-2xl">₹ {campaign.price}</p>
        </div>
        <div className="flex flex-row items-center w-full">
          <p className="text-theme-night-black-2 font-bold">View Details</p>
          <i className="pi pi-arrow-right text-theme-purple-1 ml-2"></i>
        </div>
      </div>
    </div>
  );
};

export default SingleCampaignItem;
