import { memo } from 'react';
import { Range } from 'rc-slider';
import { Accordion, AccordionTab } from 'primereact/accordion';

const SidePanel = memo(({ filterParams, changeFilters, checkFilters, setFilters, filters, onClearFilters, filterCount }) => {
  return (
    <div className="bg-white pt-8">
      <div className="flex flex-col pl-8 pr-4 justify-start items-start h-full">
        <div className="flex flex-row px-4 justify-between items-between w-full">
          <div className="font-semibold">Filters ({filterCount})</div>
          <div className="font-bold underline cursor-pointer" onClick={onClearFilters}>
            Clear All
          </div>
        </div>
        <div className="flex flex-row border border-b-0 w-full mt-4 mb-2 border-gray-300"></div>
        <div className="flex flex-col h-screen scrollbar-hide overflow-y-scroll w-full">
          <Accordion activeIndex={[]} multiple>
            {filterParams &&
              filterParams.map((param, key) => (
                <AccordionTab header={param.display_name} key={key}>
                  <div className="flex flex-col pl-4">
                    {param.type === 'category' ? (
                      <div className="flex flex-col justify-between font-medium items-center w-full" key={key}>
                        {param.values.map((value, key) => (
                          <div key={key} className="flex flex-row py-0.5 items-center w-full">
                            <input
                              type="checkbox"
                              value={param.value_type === 'dynamic' ? value.id : value}
                              onChange={e => changeFilters(e.target.value, e.target.checked, param.name, param.type, filters)}
                              checked={checkFilters(param.value_type === 'dynamic' ? value.id : value, param.name, param.type, filters)}
                              className="mr-2"
                            />
                            <p>{param.value_type === 'dynamic' ? value.name : value}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex flex-col justify-between font-medium items-center w-full" key={key}>
                        <Range
                          onChange={e => setFilters({ ...filters, [param.name]: e })}
                          step={1}
                          value={filters[param.name]}
                          min={param.min}
                          max={param.max}
                          defaultValue={[param.min, param.max]}
                        />
                      </div>
                    )}
                  </div>
                </AccordionTab>
              ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
});

export default SidePanel;
