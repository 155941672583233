import apiHelper from '../helper/apiHelper';

const checkProductAvailabilityStatus = data => {
  return apiHelper('/booking/booking_availability_check_multiple', 'POST', data, null)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw err;
    });
};

export default checkProductAvailabilityStatus;
