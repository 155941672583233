const InputsCheckout = [
  {
    type: 'Basic Details',
    fields: [
      {
        label: 'First Name',
        name: 'first_name',
        placeholder: 'Please Enter your First Name',
        type: 'text',
        required: true,
        disabled: false
      },
      {
        label: 'Last Name',
        name: 'last_name',
        placeholder: 'Please Enter your Last Name',
        type: 'text',
        required: true,
        disabled: false
      },
      {
        label: 'Phone Number',
        name: 'phone_number',
        placeholder: 'Please Enter your phone number',
        type: 'text',
        required: true,
        disabled: true
      },
      {
        label: 'Email',
        name: 'email',
        placeholder: 'Please Enter your email',
        type: 'email',
        required: false,
        disabled: false
      }
    ]
  },
  {
    type: 'Company Details',
    fields: [
      {
        label: 'Company Name',
        name: 'company',
        placeholder: 'Please Enter your company name',
        type: 'text',
        required: true,
        disabled: false
      },
      {
        label: 'Company Type',
        name: 'company_type',
        placeholder: 'Please Enter your company type',
        type: 'text',
        required: false,
        disabled: false
      },
      {
        label: 'GST Number',
        name: 'gst',
        placeholder: 'Please Enter your GST Number',
        type: 'text',
        required: false,
        disabled: false
      },
      {
        label: 'Company PAN',
        name: 'pan',
        placeholder: 'Please Enter your PAN Number',
        type: 'text',
        required: false,
        disabled: false
      }
    ]
  },
  {
    type: 'Address/Billing Details',
    fields: [
      {
        label: 'Address Line 1',
        name: 'address_line_1',
        placeholder: 'Please Enter your address line 1',
        type: 'text',
        required: true,
        disabled: false
      },
      {
        label: 'Address Line 2',
        name: 'address_line_2',
        placeholder: 'Please Enter your address line 2',
        type: 'text',
        required: false,
        disabled: false
      },
      {
        label: 'City',
        name: 'city',
        placeholder: 'Please Enter your city',
        type: 'text',
        required: true,
        disabled: false
      },
      {
        label: 'State',
        name: 'state',
        placeholder: 'Please Enter your state',
        type: 'text',
        required: true,
        disabled: false
      },
      {
        label: 'Pincode',
        name: 'pin',
        placeholder: 'Please Enter your pincode',
        type: 'Number',
        required: true,
        disabled: false
      }
    ]
  }
];

export default InputsCheckout;
