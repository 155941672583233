import { useState, useCallback, useEffect } from 'react';
import filterParamsApi from '../api/filterParams';

const useFilters = () => {
  const [filterParams, setFilterParams] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  const changeFilters = useCallback((value, checked, name, type, filters) => {
    const tempFilters = { ...filters };
    if (type === 'category') {
      if (checked) {
        if (tempFilters[name]) {
          if (tempFilters[name].includes(value)) {
            return;
          }
          tempFilters[name].push(value);
        } else {
          tempFilters[name] = [value];
        }
      } else {
        tempFilters[name] = tempFilters[name].filter(item => item !== value);
        if (tempFilters[name].length === 0) {
          delete tempFilters[name];
        }
      }
    } else {
      tempFilters[name] = value;
    }
    setFilters(tempFilters);
  }, []);

  const checkFilters = useCallback((value, name, type, filters) => {
    const tempFilters = { ...filters };
    if (type === 'category') {
      // check if the value is in the array of filters with name
      if (tempFilters[name] && tempFilters[name].includes(value.toString())) {
        return true;
      }
      return false;
    } else {
      return tempFilters[name] ? tempFilters[name] : [0, 0];
    }
  }, []);

  const getFilterParams = useCallback(async () => {
    const result = await filterParamsApi();
    if (result) {
      setFilterParams(result.data.filters);
    }
  }, []);

  const onClearFilters = useCallback(() => {
    setFilters({});
  }, []);

  useEffect(() => {
    setFilterCount(Object.keys(filters).length);
  }, [filters]);

  return {
    filterParams,
    filters,
    changeFilters,
    checkFilters,
    getFilterParams,
    setFilterParams,
    setFilters,
    onClearFilters,
    filterCount
  };
};

export default useFilters;
