import DisplayImages from '../components/DisplayImages';
import EditCampaignName from '../components/EditCampaignName';
import HomeNavbar from '../components/Navbars/HomeNavbar';
import CalendarTemplate from '../components/Calendar/CalendarTemplate';
import SingleProductCalendar from '../components/Calendar/SingleProductCalendar';
import { memo } from 'react';
import HelpModal from '../components/HelpModal';

const CustomizedCampaignPreviewScreen = memo(
  ({
    customizedCampaign,
    changeCustomizedCampaignName,
    nameDisabled,
    toggleChangeName,
    calculateTotalPriceOfCustomizedCampaign,
    getMinimumStartDayForCustomizedCampaign,
    getMinimumEndDayForCustomizedCampaign,
    onDateChangeOfWholeCampaign,
    setStartDateSelected,
    startDateSelected,
    minStartDate,
    maxEndDate,
    getProductIfInCustomizedCampaign,
    removeProductFromCustomizedCampaign,
    calculatePriceOfSingleProductInCustomizedCampaign,
    onSaveCustomizedCampaign,
    onDateChange,
    getValueOfDate,
    getMinimumEndDayOfItem,
    getMinimumEndDay,
    convertDatesArray,
    onAddToCart,
    checkCampaignIfInCart,
    removeFromCart,
    productAvailability,
    helpModal,
    setHelpModal,images
  }) => {
    return (
      <div className="w-full min-h-screen font-theme-2">
        <HomeNavbar />
        <div className="w-full">
          <div className="flex flex-col items-start  justify-start w-full px-12">
            <div className="grid grid-cols-2 gap-8 py-10 w-full">
              <div className="w-full pt-24">
                  {images.length && (
                    <DisplayImages images={images} />
                  )}
              </div>
              <div className="w-full pt-24 max-h-screen overflow-y-scroll scrollbar-hide">
                <div className="flex flex-col items-start justify-start">
                  <EditCampaignName
                    customizedCampaign={customizedCampaign}
                    changeCustomizedCampaignName={changeCustomizedCampaignName}
                    nameDisabled={nameDisabled}
                    toggleChangeName={toggleChangeName}
                    aligned="left"
                  />
                  <p className="py-0.5 text-theme-gray-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce justo, metus vitae cras arcu, elit vulputate egestas. Egestas
                    maecenas in auctor eget.
                  </p>
                  <div className="flex flex-row justify-start items-center w-full py-4">
                    <p className="text-2xl pr-4 font-semibold">₹ {calculateTotalPriceOfCustomizedCampaign()}</p>
                  </div>
                  <div className="flex flex-col items-start justify-start w-full pt-4">
                    <p className="text-normal text-theme-night-black-2">Select your Booking Dates</p>
                  </div>
                  <div className="grid grid-flow-col gap-4 items-center pb-4 pt-2">
                    <CalendarTemplate
                      required={false}
                      minDate={getMinimumStartDayForCustomizedCampaign() || ''}
                      value={minStartDate || ''}
                      placeholder="Start Date"
                      onChange={e => {
                        onDateChangeOfWholeCampaign(e.target.value, 'start');
                        setStartDateSelected(true);
                      }}
                    />
                    <CalendarTemplate
                      required={false}
                      minDate={getMinimumEndDayForCustomizedCampaign() || ''}
                      value={maxEndDate || ''}
                      placeholder="End Date"
                      onChange={e => {
                        onDateChangeOfWholeCampaign(e.target.value, 'end');
                        if (checkCampaignIfInCart('customized')) {
                          onAddToCart();
                        }
                      }}
                      disabled={!startDateSelected && !minStartDate}
                    />
                  </div>

                  <div className="grid grid-flow-col gap-4 items-center pb-4 pt-2">
                    <button
                      onClick={onSaveCustomizedCampaign}
                      className="w-full bg-white border border-theme-purple-1 text-theme-purple-1 py-2 px-6 rounded-lg hover:bg-theme-purple-1 hover:text-white"
                    >
                      Save
                    </button>
                    {!checkCampaignIfInCart('customized') ? (
                      <button className="w-full bg-theme-purple-1 text-white py-2 px-6 rounded-lg" onClick={onAddToCart}>
                        Add to Cart
                      </button>
                    ) : (
                      <button className="w-full bg-theme-purple-1 text-white py-2 px-6 rounded-lg" onClick={() => removeFromCart('customized')}>
                        Remove from Cart
                      </button>
                    )}

                    <button className="w-full bg-theme-purple-1 text-white py-2 px-6 rounded-lg hover:bg-white hover:text-theme-purple-1 hover:border hover:border-theme-purple-1">
                      Buy Now
                    </button>

                    <button
                      className="w-full border border-theme-night-black text-theme-night-black py-2 px-6 rounded-lg hover:bg-theme-night-black hover:text-white"
                      onClick={() => setHelpModal(true)}
                    >
                      Help
                    </button>
                    <HelpModal visible={helpModal} setVisible={setHelpModal} />
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex flex-col w-full px-24 py-10">
              <h2 className="text-2xl font-bold text-theme-night-black-2">Location Details</h2>
              <p className="text-normal text-theme-gray-4">All the places been covered by this campaign</p>
              <img src="/assets/images/map-temp.png" className="w-full my-4" alt="map" />
              <div className="py-8">
                <h2 className="text-2xl font-bold text-theme-night-black-2">Places in this campaign</h2>
                <p className="text-normal text-theme-gray-4">All the places been covered by this campaign</p>
                <div className="grid grid-cols-1 gap-y-12 items-center w-full my-8  rounded ">
                  {customizedCampaign?.products.map((item, key) => (
                    <div key={key} className="grid grid-cols-1/4 w-full gap-4 h-fit shadow-xl p-6">
                      <div className="flex flex-col items-start justify-center w-full h-full">
                        <img src={item.product.images[0]} className="h-full" alt="place" />
                      </div>
                      <div className="grid grid-rows-1 w-full h-fit gap-4">
                        <div className="flex flex-row justify-between items-center h-fit">
                          <div
                            className={`rounded-xl h-fit py-1.5 px-2 ${
                              productAvailability[key]?.status
                                ? 'bg-theme-light-green-1 text-theme-dark-green-1'
                                : 'bg-theme-light-red-1 text-theme-dark-red-1'
                            }  tracking-widest uppercase  font-bold text-xs`}
                          >
                            {productAvailability[key]?.status ? 'Available' : 'Unavailable'}
                          </div>
                          <div className="grid grid-flow-col gap-x-4 px-5">
                            <SingleProductCalendar
                              productInCampaign={getProductIfInCustomizedCampaign(item.product.id)}
                              type="start"
                              onDateChange={onDateChange}
                              getValueOfDate={getValueOfDate}
                              getMinimumEndDayOfItem={getMinimumEndDayOfItem}
                              getMinimumEndDay={getMinimumEndDay}
                              convertDatesArray={convertDatesArray}
                              product={item.product}
                              onAddToCartCall={false}
                              onAddToCart={onAddToCart}
                            />
                            <SingleProductCalendar
                              productInCampaign={getProductIfInCustomizedCampaign(item.product.id)}
                              type="end"
                              product={item.product}
                              onDateChange={onDateChange}
                              getValueOfDate={getValueOfDate}
                              getMinimumEndDayOfItem={getMinimumEndDayOfItem}
                              getMinimumEndDay={getMinimumEndDay}
                              convertDatesArray={convertDatesArray}
                              onAddToCartCall={true}
                              onAddToCart={onAddToCart}
                              checkCampaignIfInCart={checkCampaignIfInCart}
                              campaignType="customized"
                              campaignId={null}
                            />
                            <button onClick={() => removeProductFromCustomizedCampaign(item.product)}>
                              <img src="/assets/icons/delete.png" alt="delete" />
                            </button>
                          </div>
                        </div>
                        <div className="grid grid-cols-1/3 gap-20 w-full">
                          <div className="grid grid-flow-row gap-2 w-full">
                            <h4 className="text-lg font-bold text-theme-night-black-2">{item.product.name}</h4>
                            <p className="text-sm text-theme-gray-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <p className="text-lg font-bold text-theme-night-black-2">₹ {calculatePriceOfSingleProductInCustomizedCampaign(item)}</p>
                          </div>
                          <div className="grid grid-cols-2 gap-4 h-fit">
                            <div className="grid grid-flow-row h-fit gap-1.5">
                              <div>
                                <p className="text-sm text-theme-gray-4">Dimenssion</p>
                              </div>
                              <div>
                                <p className="text-normal text-theme-night-black-2">L x W x H</p>
                              </div>
                            </div>
                            <div className="grid grid-flow-row h-fit gap-1.5">
                              <div>
                                <p className="text-sm text-theme-gray-4">Dimenssion</p>
                              </div>
                              <div>
                                <p className="text-normal text-theme-night-black-2">L x W x H</p>
                              </div>
                            </div>
                            <div className="grid grid-flow-row h-fit gap-1.5">
                              <div>
                                <p className="text-sm text-theme-gray-4">Dimenssion</p>
                              </div>
                              <div>
                                <p className="text-normal text-theme-night-black-2">L x W x H</p>
                              </div>
                            </div>
                            <div className="grid grid-flow-row h-fit gap-1.5">
                              <div>
                                <p className="text-sm text-theme-gray-4">Dimenssion</p>
                              </div>
                              <div>
                                <p className="text-normal text-theme-night-black-2">L x W x H</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default CustomizedCampaignPreviewScreen;
