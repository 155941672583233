import { useForm } from 'react-hook-form';
import { useEffect, useState, useCallback } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import UserDetailsScreen from '../views/UserDetails';
import jwtDecode from 'jwt-decode';
import getUser from '../api/user/get';
import updateUserByPhoneApi from '../api/user/updateUserByPhone';
import checkoutApi from '../api/cart/checkout';
import bookingConfirmedApi from '../api/cart/bookingConfirmed';
import razorpayConfig from '../config/razorpay';
import { useCartContext } from '../contexts/CartContext';
import useCampaignData from '../hooks/campaignData';
import useCustomizedCampaign from '../hooks/customizedCampaign';
import useCart from '../hooks/cart';
import book from '../api/cart/book';
import useAuth from '../hooks/auth';

const UserDetails = () => {
  const { token } = useAuthContext();
  const [userData, setUserData] = useState(null);
  const { setGlobalCart, globalCart } = useCartContext();
  const { calculateTotalPriceOfCampaignData } = useCampaignData();
  const { calculateTotalPriceOfCustomizedCampaign } = useCustomizedCampaign();
  const { totalPrice, getBookingDurationArray } = useCart();
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [activeIndex, setActiveIndex] = useState([]);
  const auth = useAuth();

  const getUserDetails = useCallback(async token => {
    const result = await getUser(token);
    const user = jwtDecode(token);
    const finalData = { ...result.data[0] };
    if (result) {
      setUserData({ ...finalData, ...user, name: finalData.first_name + ' ' + finalData.last_name, pin: Number(finalData.pin) });
    }
  }, []);

  useEffect(() => {
    if (token) {
      getUserDetails(token);
      setUserAuthenticated(true);
    }
    if (!token) {
      setUserAuthenticated(false);
    }
  }, [token, getUserDetails, auth.successfullyAuthenticated]);

  useEffect(() => {
    if (userAuthenticated) {
      setActiveIndex([]);
    } else {
      setActiveIndex([0]);
    }
  }, [userAuthenticated]);

  const { register, handleSubmit } = useForm();

  const razorPayCall = useCallback(
    async (result, user, checkout) => {
      const options = {
        key: razorpayConfig.key,
        amount: checkout.data.amount,
        currency: 'INR',
        name: 'Adarth Booking' + result.order_id,
        description: 'Test Transaction',
        image: 'https://example.com/your_logo',
        order_id: checkout.data.razor_pay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async response => {
          const data = {
            order_id: result.order_id,
            razorpay_order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            amount: checkout.data.amount
          };
          const booking_confirmed = await bookingConfirmedApi(data, token);
          if (booking_confirmed) {
            setGlobalCart([]);
            if (booking_confirmed.data === 'Payment Success') {
              setPaymentSuccess(true);
            }
          }
        },
        prefill: {
          name: user.first_name + ' ' + user.last_name,
          email: user.email,
          contact: user.phone
        },
        theme: {
          color: '#3399cc'
        }
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on('payment.failed', response => {
        console.log('🚀 ~ file: CheckoutCart.js ~ line 111 ~ response', response);
      });
    },
    [token, setGlobalCart]
  );

  const callCheckout = useCallback(
    async result => {
      let user = await getUser(token);
      user = user.data[0];
      const checkout = await checkoutApi({ order_id: result.order_id, payment: 'online' }, token);
      razorPayCall(result, user, checkout);
    },
    [token, razorPayCall]
  );

  const onBook = useCallback(async () => {
    const amount = totalPrice;

    const data = {
      amount,
      data: getBookingDurationArray()
    };

    const result = await book(data, token);
    if (result) {
      return result.data;
    }
    return false;
  }, [token, totalPrice, getBookingDurationArray]);

  /**
   * @function handleFormClick
   * @description handles the click event of the form button
   * @param {object} e - event object
   * @returns {void}
   */

  const handleFormClick = useCallback(
    async e => {
      Object.keys(e).forEach(key => {
        if (key === 'name' && e[key]) {
          const name = e[key];
          const splitName = name.split(' ');
          const first_name = splitName[0];
          let last_name = splitName.map((item, key) => {
            if (key > 0) {
              return item + ' ';
            }
            return '';
          });
          if (e[key] === '') {
            delete e[key];
          }
          e.first_name = first_name;
          last_name = last_name.join('');
          last_name = String(last_name).trim();
          e.last_name = last_name;
        }
      });

      const result = await updateUserByPhoneApi(e, token);

      if (result) {
        const checkBook = await onBook();
        if (checkBook) {
          callCheckout(checkBook);
        }
      }
    },
    [token, callCheckout, onBook]
  );

  return (
    <UserDetailsScreen
      userAuthenticated={userAuthenticated}
      activeIndex={activeIndex}
      cart={globalCart}
      totalPrice={totalPrice}
      calculateTotalPriceOfCampaignData={calculateTotalPriceOfCampaignData}
      calculateTotalPriceOfCustomizedCampaign={calculateTotalPriceOfCustomizedCampaign}
      userData={userData}
      handleFormClick={handleFormClick}
      register={register}
      handleSubmit={handleSubmit}
      paymentSuccess={paymentSuccess}
      auth={auth}
    />
  );
};

export default UserDetails;
