import apiHelper from '../../helper/apiHelper';

const getAllCampaigns = async () => {
  return apiHelper('/campaign', 'GET', null, null)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default getAllCampaigns;
