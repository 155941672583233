import { Dropdown } from 'primereact/dropdown';

const yearNavigatorTemplate = e => {
  return (
    <Dropdown
      value={e.value}
      options={e.options}
      onChange={event => e.onChange(event.originalEvent, event.value)}
      className="ml-2"
      style={{ lineHeight: 1 }}
    />
  );
};

export default yearNavigatorTemplate;
