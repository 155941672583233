import { useCartContext } from '../contexts/CartContext';

const useCountCartProducts = () => {
  const { globalCart } = useCartContext();
  const countCartProducts = () => {
    return globalCart.length;
  };
  return countCartProducts();
};

export default useCountCartProducts;
