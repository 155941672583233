import { createContext, useContext, useEffect, useState } from 'react';

const CustomizedCampaignContext = createContext();

const useCustomizedCampaignContext = () => {
  return useContext(CustomizedCampaignContext);
};

const CustomizedCampaignProvider = ({ children }) => {
  const [customizedCampaign, setCustomizedCampaign] = useState(
    JSON.parse(localStorage.getItem('customizedCampaign')) || {
      name: 'Untitled Campaign',
      products: []
    }
  );

  useEffect(() => {
    localStorage.setItem('customizedCampaign', JSON.stringify(customizedCampaign));
  }, [customizedCampaign]);

  return <CustomizedCampaignContext.Provider value={{ customizedCampaign, setCustomizedCampaign }}>{children}</CustomizedCampaignContext.Provider>;
};

export { CustomizedCampaignProvider, useCustomizedCampaignContext };
