import { Rating } from 'primereact/rating';
import MultiCarousel from '../components/MultiCarousel';
import { Testimonials } from '../constants/responsive/Testmonials';

const testimonialData = [
    {
        name: "Ajoy Kumar",
        description: `Adarth helped us plan a north east zonal campaign at 
the ease of our office. The team was very 
involved from the start till the end. We just sat 
back relaxing while they took care of the 
campaign altogether.`,
        image: "/assets/images/ajoy.png",
        location: "Bangalore, India"

    },
    {
        name: "Bandana Singh",
        description: `The team was very prompt 
during the whole process. We are 
happy to work again in future 
with Adarth.`,
        image: "/assets/images/hnsite-1.jpeg",
        location: "Patna, India"

    }
]

const Testimonial = () => {
  return (
    <div className="flex flex-col w-full pb-20 px-12 pt-16 overflow-x-hidden">
      <h5 className="text-3.5xl font-bold pb-8 px-8">
        What our client<span className="text-theme-purple-1"> love about us</span>
      </h5>
      <div className="relative w-full">
        <MultiCarousel responsive={Testimonials}>
          {testimonialData.map((data, i) => (
            <div className="relative flex flex-col w-full">
              <div className="mx-4 rounded-lg py-8 px-8">
                <div className="flex flex-row">
                  <img src={data.image} alt="user" className="rounded-full mr-6 h-20 w-20" />
                  <div className="flex flex-col">
                    <p className="font-normal text-normal text-theme-night-black">
                      “{data.description}”
                    </p>
                    <p className="font-bold text-lg py-1">
                      <span className="text-theme-1">{data.name}</span>
                    </p>
                    <p className="font-bold text-theme-purple-1 pb-2">
                      <span className="text-theme-purple-1">{data.location}</span>
                    </p>
                    <Rating value={5} readOnly className="text-yellow-400 stars-rating-testimonial" cancel={false} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </MultiCarousel>
      </div>
    </div>
  );
};

export default Testimonial;
