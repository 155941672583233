import { useState, useEffect, useRef, useCallback } from 'react';

const useNavbarTransparent = () => {
  const [navbarTransparent, setNavbarTransparent] = useState(true);
  const transRef = useRef(null);

  const checkNavbarTransparent = useCallback(() => {
    clearTimeout(transRef.current);
    transRef.current = setTimeout(() => {
      if (window.scrollY > 100) {
        setNavbarTransparent(false);
      } else {
        setNavbarTransparent(true);
      }
    }, 100);
  }, []);

  useEffect(() => {
    checkNavbarTransparent();
    window.addEventListener('scroll', checkNavbarTransparent);
    return () => {
      clearTimeout(transRef.current);
      window.removeEventListener('scroll', checkNavbarTransparent);
    };
  }, [checkNavbarTransparent]);

  return navbarTransparent;
};

export default useNavbarTransparent;
