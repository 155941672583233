import useDates from './dates';
import { useCallback } from 'react';

const useCampaignPrice = () => {
  const { getBookingDuration, getDifferenceBetweenDates } = useDates();

  const calculatePriceOfSingleProductInCampaign = useCallback(
    item => {
      let { price, booking_duration } = item.product;
      const { from_date, to_date } = item;

      if (!price) {
        price = item.price;
      }

      if (from_date && to_date) {
        const fromDate = new Date(from_date);
        const toDate = new Date(to_date);
        const differenceBetweenDates = getDifferenceBetweenDates(fromDate, toDate, 'value');
        return differenceBetweenDates * price;
      } else {
        const bookingDuration = getBookingDuration(booking_duration);
        return price * bookingDuration;
      }
    },
    [getBookingDuration, getDifferenceBetweenDates]
  );

  const calculateTotalPriceOfCampaign = useCallback(
    campaign => {
      if (campaign) {
        const totalPrice = campaign.products.reduce((acc, item) => acc + calculatePriceOfSingleProductInCampaign(item), 0);
        return totalPrice;
      }
    },
    [calculatePriceOfSingleProductInCampaign]
  );

  return {
    calculatePriceOfSingleProductInCampaign,
    calculateTotalPriceOfCampaign
  };
};

export default useCampaignPrice;
