import HomeNavbar from '../components/Navbars/HomeNavbar';
import SidePanel from '../components/SidePanel';
import SingleCampaignItem from '../components/SingleCampaignItem';

const CampaignsScreen = ({ campaigns, switchToCampaign }) => {
  return (
    <div className="w-full font-theme-2 bg-white">
      <HomeNavbar />
      <div className="w-full flex flex-col pt-28">
        <div className="flex flex-col items-center justify-center py-4 w-full">
          <h2 className="text-3.5xl font-bold text-theme-night-black-2">Our Recommended Campaigns</h2>
        </div>
        <div className="flex flex-row py-4 items-center justify-center w-full">
          <input
            type="text"
            className="w-1/3 py-3 px-6 border-2 border-theme-gray-4 placeholder:text-theme-gray-4 rounded-lg"
            placeholder="Try our Recommended Campaigns"
          />
          <button className="py-3 px-6 border-2 ml-2 font-bold text-theme-purple-1 border-theme-purple-1 rounded-lg">Search</button>
        </div>
        <div className="relative grid grid-cols-1/4 w-full h-full">
          <SidePanel />
          <div className="px-8 float-right bg-white h-full bg-gradient-to-b from-slate-50 via-white to-slate-50  shadow-inner">
            <div className="flex flex-row justify-between items-center pt-12 pb-4 drop-shadow-2xl ">
              <p className="text-normal text-theme-night-black"> Showing {campaigns.length} results</p>
            </div>
            <div className={`grid grid-cols-3 py-4 gap-x-8 gap-y-6 w-full`}>
              {campaigns.map((campaign, key) => (
                <SingleCampaignItem key={key} campaign={campaign} switchToCampaign={switchToCampaign} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignsScreen;
