import apiHelper from '../../helper/apiHelper';

const getSingleProductApi = async productId => {
  return apiHelper(`/product/${productId}`, 'GET', null, null)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default getSingleProductApi;
