import { memo } from 'react';

const Button = memo(({ className, children, ...otherProps }) => {
  return (
    <button className={`bg-theme-1 my-2 py-2 px-6 text-white font-medium rounded ${className}`} {...otherProps}>
      {children}
    </button>
  );
});

export default Button;
