import { memo, useEffect, useState, useCallback } from 'react';
import bookingCampaignsApi from '../api/campaign/bookingCampaigns';
import savedCampaignsApi from '../api/campaign/savedCampaignsApi';
import { useAuthContext } from '../contexts/AuthContext';
import useCampaignDateHandlers from '../hooks/campaignDateHandlers';
import useCampaignPrice from '../hooks/campaignPrice';
import MyOrdersScreen from '../views/MyOrders';

const MyOrders = memo(() => {
  const [currentTab, setCurrentTab] = useState('saved');
  const [savedCampaigns, setSavedCampaigns] = useState([]);
  const [bookingCampaigns, setBookingCampaigns] = useState([]);
  const { token } = useAuthContext();
  const { findMinimumFromDateInCampaign, findMaximumToDateInCampaign } = useCampaignDateHandlers();
  const { calculateTotalPriceOfCampaign } = useCampaignPrice();

  const callSavedCampaignsApi = useCallback(async () => {
    const result = await savedCampaignsApi(token);
    if (result) {
      console.log('🚀 ~ file: MyOrders.js ~ line 18 ~ callSavedCampaignsApi ~ result', result);
      setSavedCampaigns(result.data.data);
    }
  }, [token]);

  const callBookingCampaignsApi = useCallback(async () => {
    const result = await bookingCampaignsApi(token);
    if (result) {
      console.log('🚀 ~ file: MyOrders.js ~ line 26 ~ callBookingCampaignsApi ~ result', result);
      setBookingCampaigns(result.data.data);
    }
  }, [token]);

  useEffect(() => {
    console.log(currentTab);
    if (currentTab === 'saved') {
      callSavedCampaignsApi();
    } else {
      callBookingCampaignsApi();
    }
  }, [currentTab, callSavedCampaignsApi, callBookingCampaignsApi]);

  return (
    <MyOrdersScreen
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      findMinimumFromDateInCampaign={findMinimumFromDateInCampaign}
      findMaximumToDateInCampaign={findMaximumToDateInCampaign}
      savedCampaigns={savedCampaigns}
      bookingCampaigns={bookingCampaigns}
      calculateTotalPriceOfCampaign={calculateTotalPriceOfCampaign}
    />
  );
});

export default MyOrders;
