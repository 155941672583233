import { useCallback, useState, useEffect, useRef, memo } from 'react';
import CustomizeScreen from '../views/Customize';
import getProductsApi from '../api/products/getProducts';
import { useNavigate } from 'react-router-dom';
import { useCustomizedCampaignContext } from '../contexts/CustomizedCampaignContext';
import useFilters from '../hooks/filters';
import useCustomizedCampaign from '../hooks/customizedCampaign';
import useDates from '../hooks/dates';
import useCampaignDateHandlers from '../hooks/campaignDateHandlers';
import checkProductAvailabilityStatus from '../api/checkProductAvailabilityStatus';

const Customize = memo(() => {
  const [products, setProducts] = useState([]);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [showCampaignError, setShowCampaignError] = useState(false);

  const timeout = useRef();

  const { filterParams, filters, changeFilters, checkFilters, getFilterParams, setFilters, onClearFilters, filterCount } = useFilters();
  const { getMinimumEndDayOfItem } = useDates();
  const { customizedCampaign } = useCustomizedCampaignContext();
  const { getMinimumStartDayForCampaign } = useCampaignDateHandlers();
  const [productAvailability, setProductAvailability] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [toDateDisabled, setToDateDisabled] = useState(true);

  const {
    changeCustomizedCampaignName,
    toggleChangeName,
    nameDisabled,
    checkIfCustomizedCampaignIsEmpty,
    setCustomizedCampaignToDefault,
    checkIfProductIsInCustomizedCampaign,
    addProductInCustomizedCampaign,
    removeProductFromCustomizedCampaign,
    addMultipleProductsInCustomizedCampaign,
    removeMultipleProductsFromCustomizedCampaign
  } = useCustomizedCampaign();

  const navigate = useNavigate();

  const getAllProducts = useCallback(async filters => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const response = await getProductsApi(filters);
      if (response) {
        setProducts(response.data.payload.results.sort((a, b) => a.id - b.id));
      }
    }, 400);
  }, []);

  const onSelectAllClicked = useCallback(
    e => {
      if (e.target.checked) {
        addMultipleProductsInCustomizedCampaign(products);
      } else {
        removeMultipleProductsFromCustomizedCampaign(products);
      }
    },
    [products, addMultipleProductsInCustomizedCampaign, removeMultipleProductsFromCustomizedCampaign]
  );

  useEffect(() => {
    getAllProducts(filters);
  }, [filters, getAllProducts]);

  useEffect(() => {
    if (customizedCampaign.products.length > 0 && customizedCampaign.products.length === products.length) {
      const selectedProductsIds = customizedCampaign.products.map(item => item.product.id);
      const productsIds = products.map(item => item.id);
      if (productsIds.every(item => selectedProductsIds.includes(item))) {
        setSelectAllSelected(true);
      } else {
        setSelectAllSelected(false);
      }
    } else {
      setSelectAllSelected(false);
    }
  }, [products, customizedCampaign]);

  useEffect(() => {
    getFilterParams();
    return () => {
      setProducts([]);
    };
  }, [getAllProducts, getFilterParams]);

  const callCheckProductAvailabilityStatus = useCallback(async data => {
    const response = await checkProductAvailabilityStatus(data);
    if (response) {
      setProductAvailability(response.data.sort((a, b) => a.product_id - b.product_id));
    }
  }, []);

  useEffect(() => {
    if (toDate && fromDate) {
      const productIds = products.map(item => item.id);
      let callApi = true;

      const data = productIds.map(item => {
        if (!fromDate || !toDate) {
          callApi = false;
        }
        return { product_id: item, from_date: fromDate, to_date: toDate };
      });

      if (callApi) {
        callCheckProductAvailabilityStatus(data);
      }
    }
  }, [toDate, callCheckProductAvailabilityStatus, fromDate, products]);

  const switchToSingleProduct = useCallback(
    product => {
      navigate(`/customized-campaign/product/${product.id}`);
    },
    [navigate]
  );

  const checkCampaign = useCallback(() => {
    const isEmpty = checkIfCustomizedCampaignIsEmpty();
    if (!isEmpty) {
      setShowCampaignError(true);
    }
  }, [checkIfCustomizedCampaignIsEmpty]);

  const onAcceptShowCampaignError = useCallback(() => {
    setShowCampaignError(false);
    setCustomizedCampaignToDefault();
  }, [setCustomizedCampaignToDefault]);

  const onRejectShowCampaignError = useCallback(() => {
    setShowCampaignError(false);
  }, []);

  return (
    <CustomizeScreen
      customizedCampaign={customizedCampaign}
      products={products}
      filters={filters}
      onClearFilters={onClearFilters}
      changeFilters={changeFilters}
      checkFilters={checkFilters}
      filterParams={filterParams}
      setFilters={setFilters}
      switchToSingleProduct={switchToSingleProduct}
      selectAllSelected={selectAllSelected}
      onSelectAllClicked={onSelectAllClicked}
      addProductInCustomizedCampaign={addProductInCustomizedCampaign}
      removeProductFromCustomizedCampaign={removeProductFromCustomizedCampaign}
      checkIfProductIsInCustomizedCampaign={checkIfProductIsInCustomizedCampaign}
      filterCount={filterCount}
      changeCustomizedCampaignName={changeCustomizedCampaignName}
      toggleChangeName={toggleChangeName}
      nameDisabled={nameDisabled}
      checkCampaign={checkCampaign}
      showCampaignError={showCampaignError}
      onAcceptShowCampaignError={onAcceptShowCampaignError}
      onRejectShowCampaignError={onRejectShowCampaignError}
      fromDate={fromDate}
      setFromDate={setFromDate}
      toDate={toDate}
      setToDate={setToDate}
      getMinimumEndDayOfItem={getMinimumEndDayOfItem}
      getMinimumStartDayForCampaign={getMinimumStartDayForCampaign}
      productAvailability={productAvailability}
      toDateDisabled={toDateDisabled}
      setToDateDisabled={setToDateDisabled}
    />
  );
});

export default Customize;
