import { memo } from 'react';

const OrderSummary = memo(({ calculateTotalPriceOfCampaignData, calculateTotalPriceOfCustomizedCampaign, cart, isVisible, totalPrice }) => {
  return (
    <div className="w-full">
      {cart.length > 0 && (
        <div
          className="w-full px-6 py-8 h-fit"
          style={{
            backgroundColor: 'rgba(229, 229, 229, 0.4)'
          }}
        >
          <div className="flex flex-col w-full py-2 bg-white ">
            <div className="flex flex-col w-full">
              <div className="grid grid-flow-row gap-2">
                <div className="text-theme-night-black-2 text-lg font-bold px-4">Order Summary</div>
                <div className="border w-full border-[rgba(229, 229, 229, 0.4)]"></div>
              </div>
              <div className="grid grid-cols-1 gap-y-2 w-full px-12">
                <div className="flex flex-row justify-between items-center w-full pt-4 pb-2">
                  <div className="text-theme-gray-4 text-sm">Items</div>
                  <div className="text-theme-gray-4 text-sm">Price</div>
                </div>
                {cart.map(item => (
                  <>
                    <div className="flex flex-row justify-between items-start w-full">
                      <div className="grid grid-flow-row gap-2 w-full">
                        <div className="text-sm text-theme-night-black-2">{item.name}</div>
                        <div className="text-sm text-theme-purple-1 font-bold">View Details</div>
                      </div>
                      <div className="text-sm text-theme-night-black-2 w-full text-right">
                        ₹ {item.type === 'customized' ? calculateTotalPriceOfCustomizedCampaign() : calculateTotalPriceOfCampaignData(item)}
                      </div>
                    </div>
                    <div className="border-b w-full border-[rgba(229, 229, 229, 0.4)]"></div>
                  </>
                ))}

                <div className="flex flex-row justify-between items-center w-full pt-4 pb-2">
                  <div className="text-theme-night-black-2 text-sm font-bold">Total Payable</div>
                  <div className="text-theme-night-black-2 text-sm">₹ {totalPrice} /-</div>
                </div>
                {isVisible && (
                  <>
                    <div className="flex flex-row items-center w-full pt-4 pb-2">
                      <input type="checkbox" required />
                      <div className="text-theme-night-black-2 pl-2 text-sm font-bold">
                        I agree to the <span className="text-theme-purple-1">Terms and Conditions</span>
                      </div>
                    </div>

                    <div className="flex flex-row w-full py-4">
                      <button type="submit" className="bg-theme-purple-1 w-full text-white text-xl font-bold py-2 px-3 rounded-lg">
                        Proceed
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default OrderSummary;
