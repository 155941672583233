import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';

const Input = memo(
  forwardRef(({ type, placeholder, className, ...props }, ref) => {
    return (
      <input ref={ref} type={type} className={`px-6 py-2 rounded shadow text-left border my-4 ${className}`} placeholder={placeholder} {...props} />
    );
  })
);

export default Input;

Input.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string
};
