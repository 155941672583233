import CampaignsScreen from '../views/Campaigns';
import { useState, useEffect } from 'react';
import getAllCampaigns from '../api/campaign/getAllCampaigns';

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const getCampaignsData = async () => {
    const campaigns = await getAllCampaigns();
    if (campaigns.status === 200) {
      setCampaigns(campaigns.data.payload.results);
    }
  };

  const switchToCampaign = campaign => {
    console.log(campaign);
  };

  useEffect(() => {
    getCampaignsData();
  }, []);

  return <CampaignsScreen campaigns={campaigns} switchToCampaign={switchToCampaign} />;
};

export default Campaigns;
