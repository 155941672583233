import apiHelper from '../../helper/apiHelper';

const bookingCampaignsApi = async token => {
  return await apiHelper(`/booked_campaigns/`, 'GET', null, token)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default bookingCampaignsApi;
