import ProductScreen from '../views/Product';
import { memo, useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import getSingleProductApi from '../api/products/getSingleProduct';
import getProductsApi from '../api/products/getProducts';
import useCustomizedCampaign from '../hooks/customizedCampaign';
import useDates from '../hooks/dates';
import useCampaignData from '../hooks/campaignData';
import callCampaignProductsApi from '../api/campaign/campaignProducts';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import callCustomizedCampaignProductsApi from '../api/campaign/callCustomizedCampaignProductsApi';
import { useCartContext } from '../contexts/CartContext';
import { useCustomizedCampaignContext } from '../contexts/CustomizedCampaignContext';
import saveCustomizedCampaign from '../api/campaign/saveCustomizedCampaign';
import useCampaignPrice from '../hooks/campaignPrice';
import findCampaignType from "../api/campaign/findCampaignType";

const Product = memo(({ type }) => {
  const params = useParams();
  const { productId, campaignId } = params;
  const [product, setProduct] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [productInCustomizedCampaign, setProductInCustomizedCampaign] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const pId = parseInt(productId);
  const { getValueOfDate, getMinimumEndDayOfItem, getMinimumEndDay, convertDatesArray } = useDates();
  const navigate = useNavigate();
  const { token } = useAuthContext();
  const { globalCart, setGlobalCart } = useCartContext();
  const { customizedCampaign } = useCustomizedCampaignContext();
  const [callAddToCart, setCallAddToCart] = useState(false);

  const {
    addProductInCustomizedCampaign,
    removeProductFromCustomizedCampaign,
    checkIfProductIsInCustomizedCampaign,
    getProductIfInCustomizedCampaign,
    onDateChange,
    setCustomizedCampaign
  } = useCustomizedCampaign();

  const { calculatePriceOfSingleProductInCampaign } = useCampaignPrice();

  const { onDateChangeOfProduct, findProductInCampaignById, findCampaignById, setCampaignData } = useCampaignData(campaign);

  const getProduct = useCallback(
    async productId => {
      if (productId) {
        const result = await getSingleProductApi(productId);
        if (result) {
          setProduct(result.data);
        } else {
          navigate('/');
        }
      }
    },
    [navigate]
  );

  const getDataOfASingleCampaign = useCallback(async () => {
    try {
      if (campaignId) {
        let campaignType = await findCampaignType(campaignId);
        let result = null;
        if (campaignType.data.data === 'standard') {
          result = await callCampaignProductsApi(campaignId);
        } else if (campaignType.data.data === 'customized') {
          if (token) {
            result = await callCustomizedCampaignProductsApi(campaignId, token);
          }
        }
        console.log(result)
        if (result.status === 200) {
          setCampaign(result.data);
        } else if (result.status !== 200) {
          navigate('/');
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [campaignId, token, navigate]);

  const changeDatesInCart = useCallback(() => {
    if (campaignId) {
      const tempCampaign = findCampaignById(parseInt(campaignId));

      const productInCart = globalCart.find(item => item?.id === tempCampaign.id);

      if (productInCart) {
        const index = globalCart.indexOf(productInCart);
        globalCart.splice(index, 1);
      }

      setGlobalCart([...globalCart, { ...tempCampaign }]);
    }
  }, [campaignId, globalCart, findCampaignById, setGlobalCart]);

  const calculatePrice = useCallback(
    (defaultPrice) =>
    {
      if (['customizedCampaign', 'product'].includes(type)) {
        const product = customizedCampaign.products.find(item => item.product.id === pId)
        if (product) {
          return calculatePriceOfSingleProductInCampaign(product)
        } else {
          return defaultPrice
        }
      } else {
        const product = findCampaignById(parseInt(campaignId)).products.find(item => item.product.id === pId)
        if (product) {
          return calculatePriceOfSingleProductInCampaign(product)
        } else {
          return defaultPrice
        }
      }
    },
    [customizedCampaign, findCampaignById, campaignId, type, calculatePriceOfSingleProductInCampaign, pId]
  );

  useLayoutEffect(() => {
    getDataOfASingleCampaign();
  }, [getDataOfASingleCampaign]);

  const getAllProducts = useCallback(async () => {
    const result = await getProductsApi();
    if (result) {
      setAllProducts(result.data.payload.results);
    }
  }, []);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  useEffect(() => {
    setProductInCustomizedCampaign(checkIfProductIsInCustomizedCampaign(productId));
  }, [getProductIfInCustomizedCampaign, productId, checkIfProductIsInCustomizedCampaign]);

  useEffect(() => {
    if (pId) {
      getProduct(pId);
    }
    return () => {
      setProduct(null);
    };
  }, [pId, getProduct]);

  const onBuyNowProduct = useCallback(async () => {
    if (token) {
      if (['customizedCampaign', 'product'].includes(type)) {
        // remove all products from cart
        setGlobalCart([]);
        addProductInCustomizedCampaign(product, null, null, product.name);

        setCallAddToCart(true);
      }
    } else {
      navigate('/verify-mobile');
    }
  }, [product, addProductInCustomizedCampaign, token, navigate, setGlobalCart, type]);

  const addToCartAndSwitchToCheckout = useCallback(async () => {
    const result = await saveCustomizedCampaign({ ...customizedCampaign, name: product.name }, token);
    if (result.status === 200) {
      setCustomizedCampaign({
        name: 'Untitled Campaign',
        products: []
      });

      setCampaignData(prev => ({
        ...prev,
        campaigns: [...prev.campaigns, result.data.data]
      }));

      const campaign_id = result.data.data.id;
      const productInCart = globalCart.find(item => item?.id === campaign_id);
      if (productInCart) {
        const index = globalCart.indexOf(productInCart);
        globalCart.splice(index, 1);
      }

      setGlobalCart([{ ...result.data.data }]);
      navigate('/user-details');
    }
  }, [customizedCampaign, token, setGlobalCart, navigate, setCampaignData, globalCart, setCustomizedCampaign, product]);

  useEffect(() => {
    if (callAddToCart) {
      addToCartAndSwitchToCheckout();
      setCallAddToCart(false);
    }
  }, [callAddToCart, addToCartAndSwitchToCheckout]);

  return (
    <ProductScreen
      productInCustomizedCampaign={productInCustomizedCampaign}
      product={product}
      allProducts={allProducts}
      addProductInCustomizedCampaign={addProductInCustomizedCampaign}
      removeProductFromCustomizedCampaign={removeProductFromCustomizedCampaign}
      checkIfProductIsInCustomizedCampaign={checkIfProductIsInCustomizedCampaign}
      getValueOfDate={getValueOfDate}
      getMinimumEndDayOfItem={getMinimumEndDayOfItem}
      getMinimumEndDay={getMinimumEndDay}
      onDateChange={['customizedCampaign', 'product'].includes(type) ? onDateChange : onDateChangeOfProduct}
      convertDatesArray={convertDatesArray}
      changeDatesInCart={changeDatesInCart}
      getProductIfInCustomizedCampaign={
        ['customizedCampaign', 'product'].includes(type) ? getProductIfInCustomizedCampaign : findProductInCampaignById
      }
      type={type}
      campaignId={campaignId}
      onBuyNowProduct={onBuyNowProduct}
      calculatePrice={calculatePrice}
    />
  );
});

export default Product;
