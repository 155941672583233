import { memo, useCallback, useLayoutEffect, useState } from 'react';
import getProductsApi from '../api/products/getProducts';
import ProductsScreen from '../views/Products';

const Products = memo(() => {
  const [products, setProducts] = useState([]);

  const getAllProducts = useCallback(async () => {
    const response = await getProductsApi();
    if (response) {
      console.log('🚀 ~ file: Products.js ~ line 11 ~ getAllProducts ~ response', response);
      setProducts(response.data.payload.results);
    }
  }, []);

  useLayoutEffect(() => {
    getAllProducts();
    return () => {
      setProducts([]);
    };
  }, [getAllProducts]); // eslint-disable-line

  return <ProductsScreen products={products} />;
});

export default Products;
