import apiHelper from '../../helper/apiHelper';

const callCustomizedCampaignProductsApi = async (campaignId, token) => {
  return await apiHelper(`/customized_campaign/${campaignId}/`, 'GET', null, token)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default callCustomizedCampaignProductsApi;
