import Input from '../components/Input';
import HomeNavbar from '../components/Navbars/HomeNavbar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import OrderSummary from '../components/OrderSummary';
import OTPInput from 'otp-input-react';
import InputsCheckout from '../constants/InputsCheckout';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';

const UserDetailsScreen = ({
  register,
  handleFormClick,
  handleSubmit,
  userData,
  calculateTotalAmount,
  cart,
  getTotalAmountOfSingleProduct,
  userAuthenticated,
  auth,
  activeIndex,
  paymentSuccess,
  totalPrice,
  calculateTotalPriceOfCampaignData,
  calculateTotalPriceOfCustomizedCampaign
}) => {
  return (
    <div className="py-24 font-theme-2 px-16 w-full min-h-screen">
      <HomeNavbar />
      <Dialog className="w-3/5 rounded-3xl font-theme-2" contentClassName="rounded-3xl" visible={paymentSuccess} closable={false} showHeader={false}>
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex flex-col py-12 items-center justify-center w-3/5">
            <img src="/assets/icons/paymentSuccess.png" alt="success" />
            <h6 className="text-center text-2xl font-bold text-theme-night-black-2 pt-4">Payment Successful</h6>
            <p className="text-center text-sm text-theme-gray-4 py-2">Please click on the below button your check your oder details and status</p>
            <Link to={'/'} className="w-full">
              <button className="btn btn-lg bg-theme-purple-1 w-full text-white rounded py-2 mt-4 px-6 btn-block btn-primary">Go to Home</button>
            </Link>
          </div>
        </div>
      </Dialog>
      <div className="flex flex-col items-center justify-center w-full py-8">
        <h2 className="text-3.5xl text-theme-night-black-2 font-bold text-center">Checkout</h2>
      </div>
      <div className="grid grid-cols-11/20 gap-12">
        <div className="flex flex-col w-full">
          <Accordion className="user-details" multiple activeIndex={activeIndex}>
            <AccordionTab
              headerClassName="bg-[#EDE7F7] rounded-lg text-theme-purple-1"
              className="border-none"
              contentClassName="user-details-content"
              disabled={userAuthenticated}
              header="Login Details"
            >
              <form
                onSubmit={
                  !auth.verificationId
                    ? auth.callSubmitPhoneNumber
                    : e => {
                        auth.callSubmitOtp(e, false);
                      }
                }
              >
                <div className="flex flex-col w-full pl-12 pr-40 py-8">
                  <p className="text-base text-theme-night-black">Phone Number</p>
                  <Input
                    name="login_phone_number"
                    className="border border-theme-night-black placeholder:theme-night-black text-theme-night-black w-full focus-within:outline-none px-4"
                    placeholder="Please Enter your phone number"
                    type="text"
                    onChange={auth.callSetMobile}
                    maxLength={10}
                    minLength={10}
                    required={false}
                    disabled={auth.verificationId}
                  />
                  <p className="text-lg font-bold text-theme-night-black-2 mt-4">Enter OTP</p>
                  <p className="text-base text-theme-night-black mt-0.5"> Please enter the OTP sent to your phone number</p>
                  <OTPInput
                    value={auth.otp}
                    onChange={auth.setOtp}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    className="my-4 text-theme-night-black-2"
                    inputStyles={{
                      height: '3rem',
                      width: '3rem'
                    }}
                    disabled={!auth.verificationId && !auth.isLoading}
                    inputClassName="border rounded-md text-black"
                    secure={false}
                  />
                  <button
                    className="w-full bg-theme-purple-1 my-4 text-white font-bold py-3 px-4 rounded-md focus:outline-none"
                    disabled={!auth.verificationId && auth.disabled && auth.isLoading}
                  >
                    {auth.verificationId ? 'Submit OTP' : 'Submit Phone Number'}
                  </button>
                </div>
              </form>
            </AccordionTab>
          </Accordion>

          <form onSubmit={handleSubmit(handleFormClick)}>
            <Accordion className="user-details" multiple>
              {InputsCheckout.map((input, index) => (
                <AccordionTab
                  key={index}
                  headerClassName="bg-[#EDE7F7] rounded-lg text-theme-purple-1"
                  className="border-none my-4"
                  contentClassName="user-details-content"
                  header={input.type}
                  disabled={!userAuthenticated}
                >
                  <div className="flex flex-col w-full py-8">
                    <div className="px-12 rounded-b-xl bg-white">
                      {input.fields.map((field, key) => (
                        <div key={key}>
                          <label className="block text-theme-night-black">
                            {field.label} {!field.required && '(Optional)'}:
                          </label>
                          <Input
                            type={field.type}
                            name={field.name}
                            defaultValue={userData && userData[field.name]}
                            placeholder={field.placeholder}
                            onChange={register}
                            className="border border-theme-night-black placeholder:text-theme-night-black text-theme-night-black w-96 focus-within:outline-none px-4"
                            disabled={field.disabled}
                            required={field.required}
                            {...register(field.name)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </AccordionTab>
              ))}
            </Accordion>
            <Accordion className="user-details">
              <AccordionTab
                headerClassName="bg-[#EDE7F7] rounded-lg text-theme-purple-1"
                className="border-none mb-4"
                contentClassName="user-details-content"
                header="Payment Method"
                disabled={!userAuthenticated}
              >
                <div className="flex flex-col w-full pl-12 pr-40 py-8">
                  <div className="flex flex-col w-full ml-4">
                    <div className="flex flex-row w-full items-center">
                      <input type="radio" name="payment_method" id="cash" value="cash" className="mr-4" />
                      <label htmlFor="cash" className="text-theme-night-black">
                        Cash
                      </label>
                    </div>
                    <div className="flex flex-row w-full items-center">
                      <input type="radio" name="payment_method" id="razorpay" value="razorpay" className="mr-4" />
                      <label htmlFor="razorpay" className="text-theme-night-black">
                        Razorpay
                      </label>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
            <div className="flex flex-row justify-center items-center w-full">
              <button type="submit" className="bg-theme-purple-1 text-white font-bold py-3 w-3/5 rounded-lg focus:outline-none">
                Place Order
              </button>
            </div>
          </form>
        </div>
        <OrderSummary
          cart={cart}
          totalPrice={totalPrice}
          calculateTotalPriceOfCampaignData={calculateTotalPriceOfCampaignData}
          calculateTotalPriceOfCustomizedCampaign={calculateTotalPriceOfCustomizedCampaign}
          isVisible={false}
        />
      </div>
    </div>
  );
};

export default UserDetailsScreen;
