export const indicatorStyles = {
  width: '1rem',
  height: '1rem',
  display: 'inline-block',
  margin: '0 8px'
};

export const searchBg = {
  backgroundPositionX: 'calc(100% - 1rem)'
};
