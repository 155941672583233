import apiHelper from '../helper/apiHelper';

const loginWithPhone = async (data, token) => {
  return await apiHelper('/auth/login_with_phone/', 'POST', { ...data }, token)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};
export default loginWithPhone;
