import apiHelper from '../helper/apiHelper';

const validateBookingDates = async cart => {
  return await apiHelper('/booking/validate', 'POST', cart)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw err;
    });
};

export default validateBookingDates;
