import apiHelper from '../../helper/apiHelper';

const savedCampaignsApi = async token => {
  return await apiHelper(`/saved_campaigns/`, 'GET', null, token)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default savedCampaignsApi;
