import apiHelper from '../../helper/apiHelper';

const callCampaignProductsApi = async campaignId => {
  return await apiHelper(`/campaign/${campaignId}`, 'GET')
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default callCampaignProductsApi;
