import { useCartContext } from '../contexts/CartContext';
import { useCallback, useState, useEffect } from 'react';
import useCampaignData from './campaignData';
import useCustomizedCampaign from './customizedCampaign';

const useCart = () => {
  const { globalCart, setGlobalCart } = useCartContext();
  const [totalPrice, setTotalPrice] = useState(0);
  const { calculateTotalPriceOfCampaignData } = useCampaignData();
  const { calculateTotalPriceOfCustomizedCampaign } = useCustomizedCampaign();

  const calculateTotalPrice = useCallback(() => {
    return globalCart.reduce((acc, item) => {
      if (item.type === 'customized') {
        return acc + calculateTotalPriceOfCustomizedCampaign(item);
      } else {
        return acc + calculateTotalPriceOfCampaignData(item);
      }
    }, 0);
  }, [globalCart, calculateTotalPriceOfCampaignData, calculateTotalPriceOfCustomizedCampaign]);

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [calculateTotalPrice, globalCart]);

  const getBookingDurationArray = useCallback(() => {
    const cartData = globalCart.map(item => {
      const products = item.products.map(product => {
        return {
          from_date: new Date(product.from_date),
          to_date: new Date(product.to_date),
          product_id: product.product.id,
          campaign_id: item.id,
          customized: item.type === 'customized'
        };
      });

      return {
        products
      };
    });

    const cartDataFlatten = cartData.reduce((acc, curr) => {
      return acc.concat(curr.products);
    }, []);

    return cartDataFlatten;
  }, [globalCart]);

  const checkCampaignIfInCart = useCallback(
    (type, campaignId = null) => {
      if (type === 'customized') {
        return globalCart.some(item => item.type === 'customized');
      } else {
        return globalCart.some(item => item.id === campaignId);
      }
    },
    [globalCart]
  );

  const removeFromCart = useCallback(
    (type, campaignId = null) => {
      if (type === 'customized') {
        setGlobalCart(globalCart.filter(item => item.type !== 'customized'));
      } else {
        setGlobalCart(globalCart.filter(item => item.id !== campaignId));
      }
    },
    [globalCart, setGlobalCart]
  );

  return {
    totalPrice,
    getBookingDurationArray,
    checkCampaignIfInCart,
    removeFromCart
  };
};

export default useCart;
