const ContactUs = ({ onContactSubmit }) => {
  return (
    <div className="grid grid-cols-1/4 gap-x-32 px-28 py-12">
      <div className="flex flex-col">
        <h2 className="text-3.5xl font-bold pb-2 text-theme-night-black-2">
          How can <span className="text-theme-purple-1">I help you?</span>
        </h2>
        <p className="text-base text-theme-night-black">Fill up the form and our Team will get back to you soon.</p>
        <div className="grid grid-flow-row gap-y-2 py-4">
          <div className="flex flex-row items-center w-full text-theme-night-black-2">
            <i className="pi pi-phone pr-4" />
            <p className="text-base ">+91-7022160437</p>
          </div>
          <div className="flex flex-row items-center w-full text-theme-night-black-2">
            <i className="pi pi-envelope pr-4" />
            <p className="text-base ">info@adarth.in</p>
          </div>
          <div className="flex flex-row items-center w-full text-theme-night-black-2">
            <i className="pi pi-map-marker pr-4" />
            <p className="text-base ">Building no: 2751, 31st Main Rd, PWD Quarters, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102</p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center w-full py-4">
          <img src="/assets/images/Map.jpg" alt="star" className="w-full h-full" />
        </div>
      </div>
      <form className="flex flex-col" onSubmit={e => onContactSubmit(e)}>
        <div className="grid grid-cols-2 gap-6">
          <div className="grid grid-flow-row gap-2">
            <div>
              <label className="text-theme-night-black">Name</label>
            </div>
            <div>
              <input
                type="text"
                className="w-full border border-theme-night-black py-3 px-6 rounded"
                placeholder="Please enter your name"
                name="name"
                required
              />
            </div>
          </div>
          <div className="grid grid-flow-row gap-2">
            <div>
              <label className="text-theme-night-black">Company Name</label>
            </div>
            <div>
              <input
                type="text"
                className="w-full border border-theme-night-black py-3 px-6 rounded"
                placeholder="Please enter your company name"
                name="company_name"
                required
              />
            </div>
          </div>
          <div className="grid grid-flow-row gap-2">
            <div>
              <label className="text-theme-night-black">Phone</label>
            </div>
            <div>
              <input
                type="number"
                minLength={10}
                maxLength={10}
                className="w-full border border-theme-night-black py-3 px-6 rounded"
                placeholder="Please enter your phone number"
                name="phone_number"
                required
              />
            </div>
          </div>
          <div className="grid grid-flow-row gap-2">
            <div>
              <label className="text-theme-night-black">Email</label>
            </div>
            <div>
              <input
                type="email"
                className="w-full border border-theme-night-black py-3 px-6 rounded"
                placeholder="Please enter your email"
                name="email"
                required
              />
            </div>
          </div>
        </div>
        <div className="grid grid-flow-row gap-2 pt-4">
          <div>
            <label className="text-theme-night-black">Message</label>
          </div>
          <div>
            <textarea
              className="w-full border border-theme-night-black py-3 px-6 rounded h-28 resize-none"
              placeholder="Please enter your message"
              name="message"
              required
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center w-full pt-4">
          <div className="flex flex-row items-center">
            <input type="checkbox" className="mr-4 border border-theme-night-black-2 h-4 w-4" required />
            <p className="text-theme-night-black-2">
              I agree to share my details with <span className="text-theme-purple-1 font-bold">Adarth</span>
            </p>
          </div>
          <button className="bg-theme-purple-1 text-white font-bold py-2 px-8 rounded">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
