import apiHelper from '../helper/apiHelper';

const callHelpApi = async data => {
  return await apiHelper('/help/', 'POST', data)
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export default callHelpApi;
