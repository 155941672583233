import { memo } from 'react';
import TopCarousel from '../components/Products/TopCarousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Navbar from '../components/Navbars/Navbar';
import CampaignsMain from '../components/Products/CampaignsMain';

const ProductsScreen = memo(({ products }) => (
  <div className="bg-theme-4">
    <Navbar />
    <div className="w-full font-theme-1">
      <TopCarousel products={products} />
      <div className="mt-24 pb-24 text-theme-1">
        <div className="mx-16 py-8 px-12 bg-white border border-theme-2 rounded">
          <CampaignsMain type={'Recommended Campaigns'} />
          <CampaignsMain type={'Customize Campaigns'} />
        </div>
      </div>
    </div>
  </div>
));

export default ProductsScreen;
