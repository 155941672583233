import SingleItem from '../components/SingleItem';
import { memo } from 'react';
import specificationsKeys from '../constants/Specifications';
import DisplayImages from '../components/DisplayImages';
import HomeNavbar from '../components/Navbars/HomeNavbar';
import Testimonial from '../components/Testimonial';
import Footer from '../components/Footer';
import SingleProductCalendar from '../components/Calendar/SingleProductCalendar';

const ProductScreen = memo(
  ({
    product,
    allProducts,
    getProductIfInCustomizedCampaign,
    addProductInCustomizedCampaign,
    removeProductFromCustomizedCampaign,
    checkIfProductIsInCustomizedCampaign,
    getValueOfDate,
    getMinimumEndDayOfItem,
    getMinimumEndDay,
    convertDatesArray,
    onDateChange,
    type,
    campaignId,
    changeDatesInCart,
    onBuyNowProduct,
    calculatePrice
  }) => {
    const available = 1;
    return (
      product && (
        <div className="w-full min-h-screen font-theme-2">
          <HomeNavbar />
          <div className="w-full">
            <div className="flex flex-col items-start  justify-start w-full px-12">
              <div className="grid grid-cols-2 gap-20 py-10 w-full">
                <div className="w-full pt-24">
                  {product.images && product.images.length &&(
                    <DisplayImages images={product.images} />
                  )}
                </div>
                <div className="w-full pt-24 max-h-screen overflow-y-scroll scrollbar-hide">
                  <div className="flex flex-col items-start justify-start">
                    <div
                      className={`rounded-xl py-1.5 mb-2 px-2 ${
                        available ? 'bg-theme-light-green-1 text-theme-dark-green-1' : 'bg-theme-light-red-1 text-theme-dark-red-1'
                      }  tracking-widest uppercase  font-bold text-xs`}
                    >
                      {available ? 'Available' : 'Unavailable'}
                    </div>
                    <h2 className="font-bold pb-2 text-3.5xl">{product.name}</h2>
                    <p className="py-0.5 text-theme-gray-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce justo, metus vitae cras arcu, elit vulputate egestas. Egestas
                      maecenas in auctor eget.
                    </p>
                    <div className="flex flex-row justify-start items-center w-full py-4">
                      <p className="text-2xl pr-4 font-semibold">₹ {calculatePrice(product.price)}</p>
                    </div>
                    <p className="text-theme-night-black-2">Schedule your Booking Dates</p>
                    <div className="flex flex-row justify-start items-start w-fit h-fit py-2">
                      <div className="grid grid-flow-col gap-x-4 gap-y-4">
                        <SingleProductCalendar
                          getValueOfDate={getValueOfDate}
                          getMinimumEndDayOfItem={getMinimumEndDayOfItem}
                          getMinimumEndDay={getMinimumEndDay}
                          convertDatesArray={convertDatesArray}
                          type="start"
                          product={product}
                          productInCampaign={
                            ['customizedCampaign', 'product'].includes(type)
                              ? getProductIfInCustomizedCampaign(product.id)
                              : getProductIfInCustomizedCampaign(campaignId, product.id)
                          }
                          onDateChange={(product, date, type) => {
                            onDateChange(product, date, type);
                            type !== 'customizedCampaign' && changeDatesInCart();
                          }}
                        />
                        <SingleProductCalendar
                          getValueOfDate={getValueOfDate}
                          getMinimumEndDayOfItem={getMinimumEndDayOfItem}
                          getMinimumEndDay={getMinimumEndDay}
                          convertDatesArray={convertDatesArray}
                          type="end"
                          product={product}
                          productInCampaign={
                            ['customizedCampaign', 'product'].includes(type)
                              ? getProductIfInCustomizedCampaign(product.id)
                              : getProductIfInCustomizedCampaign(campaignId, product.id)
                          }
                          onDateChange={(product, date, type) => {
                            onDateChange(product, date, type);
                            ['customizedCampaign', 'product'].includes(type) && changeDatesInCart();
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-start w-fit h-fit py-2">
                      <div className="grid grid-flow-col gap-x-4 gap-y-4">
                        {type === 'customizedCampaign' && (
                          <button
                            className={`bg-transparent font-bold py-2 px-8 rounded-lg focus-within:outline-none border-2 border-theme-purple-1 text-theme-purple-1`}
                          >
                            Save
                          </button>
                        )}
                        <button
                          onClick={
                            checkIfProductIsInCustomizedCampaign(product.id)
                              ? () => removeProductFromCustomizedCampaign(product)
                              : () => addProductInCustomizedCampaign(product)
                          }
                          className={`bg-transparent font-bold py-2 px-8 rounded-lg focus-within:outline-none border-2 ${
                            !checkIfProductIsInCustomizedCampaign(product.id)
                              ? 'border-theme-purple-1 text-theme-purple-1'
                              : 'border-theme-cherry-red-1 text-theme-cherry-red-1'
                          }`}
                        >
                          {checkIfProductIsInCustomizedCampaign(product.id) ? 'Remove from cart' : 'Add to cart'}
                        </button>
                        <button
                          className="bg-theme-purple-1 font-bold py-2 px-8 rounded-lg focus-within:outline-none border-2 border-theme-purple-1 text-white"
                          onClick={onBuyNowProduct}
                        >
                          Buy now
                        </button>
                      </div>
                    </div>
                    <h2 className="text-normal text-theme-night-black-2 py-4">Specifications</h2>
                    <div className="grid grid-flow-row gap-y-12 w-full">
                      <div className="flex flex-col items-start justify-start w-full border py-4 px-6 border-theme-gray-2 rounded-xl">
                        <div className="grid grid-cols-2 gap-x-20 gap-y-2 items-start justify-between w-full">
                          {specificationsKeys.map(key => (
                            <>
                              {product[key.key] && (
                                <div className="flex flex-col items-start justify-start w-full">
                                  <div className="font-normal text-sm text-theme-gray-4">{key.name}</div>
                                  <div className="font-normal text-base py-1 text-theme-night-black-2">{product[key.key]}</div>
                                </div>
                              )}
                            </>
                          ))}
                          {specificationsKeys.map(key => (
                            <>
                              {product[key.key] && (
                                <div className="flex flex-col items-start justify-start w-full">
                                  <div className="font-normal text-sm text-theme-gray-4">{key.name}</div>
                                  <div className="font-normal text-base py-1 text-theme-night-black-2">{product[key.key]}</div>
                                </div>
                              )}
                            </>
                          ))}
                          {specificationsKeys.map(key => (
                            <>
                              {product[key.key] && (
                                <div className="flex flex-col items-start justify-start w-full">
                                  <div className="font-normal text-sm text-theme-gray-4">{key.name}</div>
                                  <div className="font-normal text-base py-1 text-theme-night-black-2">{product[key.key]}</div>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start w-full border py-4 px-6 border-theme-gray-2 rounded-xl">
                        <div className="grid grid-cols-2 gap-x-20 gap-y-4 items-start justify-between w-full">
                          <div className="flex flex-col items-start justify-start w-full">
                            <div className="grid grid-flow-row gap-x-4 gap-y-4 w-full">
                              <div className="w-full">
                                <div className="font-normal text-sm text-theme-gray-4">Address</div>
                                <p className="font-normal text-base py-1 text-theme-night-black-2">
                                  Melvin Porter P.O. Box 132 1599 Curabitur Rd. Bandera South Dakota 45149
                                </p>
                              </div>
                              <div className="w-full">
                                <div className="grid grid-cols-2 justify-between w-full gap-x-6 gap-y-2">
                                  <div className="w-full">
                                    <p className="font-normal text-sm text-theme-gray-4">District</p>
                                    <p className="font-normal text-base py-1 text-theme-night-black-2">Bengaluru</p>
                                  </div>
                                  <div className="w-full">
                                    <p className="font-normal text-sm text-theme-gray-4">State</p>
                                    <p className="font-normal text-base py-1 text-theme-night-black-2">Bengaluru</p>
                                  </div>
                                  <div className="w-full">
                                    <p className="font-normal text-sm text-theme-gray-4">Pin Code</p>
                                    <p className="font-normal text-base py-1 text-theme-night-black-2">121006</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-full h-full">
                            <img src="/assets/images/Map.jpg" alt="map" className="w-full h-full" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <div className="px-20">
                <h5 className="text-3.5xl font-bold py-6">
                  Sites you may <span className="text-theme-purple-1">Like</span>
                </h5>
                <div className="grid grid-cols-4 gap-8 w-full">
                  {allProducts.map((product, key) => (
                    <SingleItem
                      key={key}
                      product={product}
                      addProductInCustomizedCampaign={addProductInCustomizedCampaign}
                      removeProductFromCustomizedCampaign={removeProductFromCustomizedCampaign}
                      checkIfProductIsInCustomizedCampaign={checkIfProductIsInCustomizedCampaign}
                      showCheckBox={false}
                      buttonText="Add"
                    />
                  ))}
                </div>
              </div>
              <Testimonial />
            </div>
          </div>
          <Footer />
        </div>
      )
    );
  }
);

export default ProductScreen;
