import apiHelper from '../../helper/apiHelper';

const updateUserByPhoneApi = async (data, token) => {
  const result = await apiHelper('/auth/update_user_by_phone/', 'PATCH', data, token)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
  return result;
};

export default updateUserByPhoneApi;
