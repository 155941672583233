import React from 'react';

const EditCampaignName = ({ nameDisabled, changeCustomizedCampaignName, customizedCampaign, toggleChangeName, aligned }) => {
  return (
    <div className={`flex flex-row justify-${aligned} w-full`}>
      <input
        className={`text-3.5xl font-bold text-${aligned} bg-white ${
          !nameDisabled ? 'border-2 border-theme-night-black-2 rounded-md ' : ''
        } justify-center text-theme-night-black-2 items-center w-fit`}
        onChange={e => changeCustomizedCampaignName(e.target.value)}
        onMouseLeave={() => toggleChangeName(true)}
        value={customizedCampaign?.name}
        disabled={nameDisabled}
      />
      <div className="flex flex-col justify-center items-center px-4">
        <img
          src={'/assets/icons/edit.png'}
          alt="edit"
          className="cursor-pointer flex flex-col h-8 w-8 items-center justify-center"
          onClick={() => toggleChangeName(false)}
        />
      </div>
    </div>
  );
};

export default EditCampaignName;
