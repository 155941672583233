import SingleItem from '../components/SingleItem';
import HomeNavbar from '../components/Navbars/HomeNavbar';
import SidePanel from '../components/SidePanel';
import { memo } from 'react';
import { searchBg } from '../styles/products';
import Footer from '../components/Footer';
import { ConfirmDialog } from 'primereact/confirmdialog';
import EditCampaignName from '../components/EditCampaignName';
import { Link } from 'react-router-dom';
import CalendarTemplate from '../components/Calendar/CalendarTemplate';

const CustomizeScreen = memo(
  ({
    products,
    onSelectAllClicked,
    selectAllSelected,
    switchToSingleProduct,
    filterParams,
    changeFilters,
    checkFilters,
    filters,
    setFilters,
    addProductInCustomizedCampaign,
    removeProductFromCustomizedCampaign,
    checkIfProductIsInCustomizedCampaign,
    onClearFilters,
    filterCount,
    customizedCampaign,
    toggleChangeName,
    nameDisabled,
    changeCustomizedCampaignName,
    checkCampaign,
    showCampaignError,
    onAcceptShowCampaignError,
    onRejectShowCampaignError,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    productAvailability,
    getMinimumEndDayOfItem,
    getMinimumStartDayForCampaign,
    toDateDisabled,
    setToDateDisabled
  }) => {
    return (
      <div className="w-full max-h-screen font-theme-2">
        <HomeNavbar checkCampaign={checkCampaign} page="customize campaign" />
        <ConfirmDialog
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          contentClassName="flex flex-col items-center py-4 text-theme-red-1"
          message="Do you want to reset your campaign?"
          visible={showCampaignError}
          acceptLabel="Yes"
          rejectLabel="No"
          acceptIcon="pi pi-check"
          rejectIcon="pi pi-times"
          acceptClassName="bg-theme-purple-1 text-white rounded-lg"
          rejectClassName="bg-white text-theme-purple-1 rounded-lg border-none"
          accept={onAcceptShowCampaignError}
          reject={onRejectShowCampaignError}
        />

        <div className="flex flex-col w-full min-h-screen">
          <div className="flex flex-col pt-28 w-full">
            <EditCampaignName
              customizedCampaign={customizedCampaign}
              toggleChangeName={toggleChangeName}
              nameDisabled={nameDisabled}
              changeCustomizedCampaignName={changeCustomizedCampaignName}
              aligned="center"
            />
            <div className="flex flex-row justify-between px-8 w-full pt-4 pb-8 border-b ">
              <input
                style={{ ...searchBg }}
                type="text"
                className="py-2 w-1/3 px-6 mr-8 border border-theme-gray-4 rounded-md bg-search bg-no-repeat bg-right"
                placeholder="Search by product name"
                onChange={e => setFilters(prevState => ({ ...prevState, search: e.target.value }))}
              />
              <CalendarTemplate
                onChange={e => {
                  setFromDate(e.target.value);
                  setToDate('');
                  setToDateDisabled(false);
                }}
                value={fromDate}
                minDate={getMinimumStartDayForCampaign()}
                placeholder="Start Date"
              />
              <CalendarTemplate
                onChange={e => setToDate(e.target.value)}
                value={toDate}
                minDate={getMinimumEndDayOfItem(fromDate, 7)}
                disabled={toDateDisabled}
                placeholder="End Date"
              />
              <div className="flex flex-row items-center justify-center ml-4 mr-8">
                <input type="checkbox" className="mx-4" /> Flexible with Date
              </div>
              <button className="bg-transparent font-bold py-2 px-6 rounded-lg focus-within:outline-none border border-theme-purple-1 text-theme-purple-1">
                Search
              </button>
            </div>
          </div>
          <div className="relative grid grid-cols-1/4 w-full h-full">
            <SidePanel
              changeFilters={changeFilters}
              setFilters={setFilters}
              filters={filters}
              checkFilters={checkFilters}
              filterParams={filterParams}
              onClearFilters={onClearFilters}
              filterCount={filterCount}
            />
            <div className="px-8 float-right bg-white h-full bg-gradient-to-b from-slate-50 via-white to-slate-50  shadow-inner">
              <div className="flex flex-row justify-between items-center pt-12 pb-4 drop-shadow-2xl ">
                <p className="text-normal text-theme-night-black"> Showing {products.length} results</p>
                <div className="flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <input
                      type="checkbox"
                      onChange={onSelectAllClicked}
                      checked={selectAllSelected}
                      className="accent-transparent checked:text-black checked:font-bold w-4"
                    />
                    <p className="text-normal text-theme-night-black mx-4">Select all products</p>
                  </div>
                  <Link to="/customized-campaign-preview">
                    <button className="font-bold py-2 px-6 rounded-lg focus-within:outline-none bg-theme-purple-1 text-white">Proceed</button>
                  </Link>
                </div>
              </div>
              <div className={`grid grid-cols-3 py-4 gap-x-8 gap-y-6 w-full`}>
                {products.map((product, key) => (
                  <SingleItem
                    checkIfProductIsInCustomizedCampaign={checkIfProductIsInCustomizedCampaign}
                    removeProductFromCustomizedCampaign={removeProductFromCustomizedCampaign}
                    addProductInCustomizedCampaign={addProductInCustomizedCampaign}
                    keyId={key}
                    fromDate={fromDate}
                    toDate={toDate}
                    productAvailability={productAvailability}
                    product={product}
                    switchToSingleProduct={switchToSingleProduct}
                    toBeShownAvailability={true}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
);

export default CustomizeScreen;
