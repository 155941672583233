import React from 'react';
import ReactDOM from 'react-dom';
import 'rc-slider/assets/index.css';
import 'react-multi-carousel/lib/styles.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import 'tailwindcss/tailwind.css';
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
