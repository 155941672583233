import { memo } from 'react';
import { Link } from 'react-router-dom';
import useCountCartProducts from '../../hooks/countCartProducts';
import Button from '../Button';

const Navbar = memo(({ showHamBurger, onHamBurgerClicked }) => {
  return (
    <div className="fixed top-0 left-0 flex py-2 flex-row items-center font-theme-1 bg-theme-2 justify-between w-full z-30">
      <div className="float-left px-16">
        <div className="flex flex-row items-center justify-center">
          {showHamBurger && <i onClick={onHamBurgerClicked} className="pi pi-align-justify text-white not-italic mr-4 text-normal"></i>}
          <Link to="/">
            <p className={`text-white text-semibold ${!showHamBurger ? 'p-2.5' : 'p-0.5'}  text-2xl`}>Adarth</p>
          </Link>
        </div>
      </div>
      <div className="float-right px-20">
        <div className="flex flex-row items-center justify-center">
          <div className="relative">
            <Link to="/cart">
              <Button className="bg-white text-white font-bold py-2 px-3 rounded-full">
                <i className="pi pi-shopping-cart text-theme-1 not-italic text-normal"></i>
                <div className="absolute flex flex-col items-center justify-center right-0 h-4 w-4 rounded-full bg-red-500 top-0">
                  <p
                    className="text-white font-light"
                    style={{
                      fontSize: '0.5rem'
                    }}
                  >
                    {useCountCartProducts()}
                  </p>
                </div>
              </Button>
            </Link>
          </div>
          <div>
            <Link to="/verify-mobile">
              <Button className={'mx-3 text-theme-2 bg-white font-bold'}>Signup</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Navbar;
