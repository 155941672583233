import { useCampaignDataContext } from '../contexts/CampaignData';
import useCampaignDateHandlers from './campaignDateHandlers';
import { useCallback } from 'react';
import useCampaignPrice from './campaignPrice';
import { useCartContext } from '../contexts/CartContext';

const useCampaignData = campaign => {
  const { campaignData, setCampaignData } = useCampaignDataContext();
  const { globalCart, setGlobalCart } = useCartContext();

  const {
    findMaximumBookingDurationInCampaign,
    findMaximumToDateInCampaign,
    findMinimumFromDateInCampaign,
    getMinimumEndDayForCampaign,
    getMinimumStartDayForCampaign
  } = useCampaignDateHandlers();

  const { calculatePriceOfSingleProductInCampaign, calculateTotalPriceOfCampaign } = useCampaignPrice();

  const findCampaignById = useCallback(
    (id, type) => {
      const tempCampaign = campaignData?.campaigns?.find(campaign => campaign?.id === parseInt(id));
      if (!tempCampaign && type !== 'onDateChangeOfProduct' && campaign) {
        return campaign;
      }
      return tempCampaign;
    },
    [campaignData, campaign]
  );

  const findProductInCampaignById = useCallback(
    (campaignId, productId) => {
      return findCampaignById(campaignId)?.products?.find(product => product.product.id === parseInt(productId));
    },
    [findCampaignById]
  );

  const onMultipleDateChange = (date, type) => {
    const findCampaign = findCampaignById(campaign?.id);
    let temp;
    if (!findCampaign) {
      temp = campaign;

      temp.products.forEach(item => {
        if (type === 'start') {
          item.from_date = date;
          item.to_date = null;
        } else if (type === 'end') {
          item.to_date = date;
        }
      });

      setCampaignData({
        campaigns: [...campaignData.campaigns, temp]
      });
    } else {
      temp = findCampaign;
      temp.products.forEach(item => {
        if (type === 'start') {
          item.from_date = date;
          item.to_date = null;
        } else if (type === 'end') {
          item.to_date = date;
        }
      });

      setCampaignData({
        campaigns: [...campaignData.campaigns.filter(item => item.id !== campaign.id), temp]
      });
    }
  };

  const onDateChangeOfProduct = (product, date, type) => {
    const findCampaign = findCampaignById(campaign.id, 'onDateChangeOfProduct');

    if (!findCampaign) {
      let temp = campaign;

      temp.products.forEach(item => {
        if (item.product.id === product.id) {
          if (type === 'start') {
            item.from_date = date;
            item.to_date = null;
          } else if (type === 'end') {
            item.to_date = date;
          }
        }
      });

      setCampaignData({
        campaigns: [...campaignData.campaigns, temp]
      });
    } else {
      findCampaign.products.forEach(item => {
        if (item.product.id === product.id) {
          if (type === 'start') {
            item.from_date = date;
            item.to_date = null;
          } else if (type === 'end') {
            item.to_date = date;
          }
        }
      });

      setCampaignData({
        campaigns: [...campaignData.campaigns]
      });
    }
  };

  const findMaximumBookingDurationInCampaignData = useCallback(
    campaignId => findMaximumBookingDurationInCampaign(findCampaignById(campaignId)),
    [findCampaignById, findMaximumBookingDurationInCampaign]
  );

  const findMinimumFromDateInCampaignData = useCallback(
    campaignId => {
      const findCampaign = findCampaignById(campaignId);
      if (findCampaign) {
        return findMinimumFromDateInCampaign(findCampaign);
      }
    },
    [findMinimumFromDateInCampaign, findCampaignById]
  );

  const findMaximumToDateInCampaignData = useCallback(
    campaignId => {
      const findCampaign = findCampaignById(campaignId);
      if (findCampaign) {
        return findMaximumToDateInCampaign(findCampaign);
      }
    },
    [findMaximumToDateInCampaign, findCampaignById]
  );

  const getMinimumStartDayForCampaignData = useCallback(
    campaignId => getMinimumStartDayForCampaign(findCampaignById(campaignId)),
    [findCampaignById, getMinimumStartDayForCampaign]
  );

  const getMinimumEndDayForCampaignData = useCallback(
    campaign => {
      const findCampaign = findCampaignById(campaign?.id);
      if (findCampaign) {
        return getMinimumEndDayForCampaign(findCampaign);
      }
    },
    [getMinimumEndDayForCampaign, findCampaignById]
  );

  const calculatePriceOfSingleProductInCampaignData = useCallback(
    product => calculatePriceOfSingleProductInCampaign(product),
    [calculatePriceOfSingleProductInCampaign]
  );

  const checkDatesOfAllProductsIfSelected = useCallback(
    campaignId => {
      const tempCampaign = findCampaignById(campaignId);
      console.log('🚀 ~ file: campaignData.js ~ line 160 ~ tempCampaign', tempCampaign);
      let isAllSelected = true;
      if (tempCampaign.products.length > 0) {
        tempCampaign.products.every(product => {
          if (!product.from_date || !product.to_date) {
            alert('Please select the date for all products in this campaign.');
            isAllSelected = false;
            return false;
          }
          return true;
        });
      }
      return {
        isAllSelected,
        tempCampaign
      };
    },
    [findCampaignById]
  );

  const calculateTotalPriceOfCampaignData = useCallback(campaign => calculateTotalPriceOfCampaign(campaign), [calculateTotalPriceOfCampaign]);

  const onAddToCart = useCallback(
    campaignId => {
      const { isAllSelected, tempCampaign } = checkDatesOfAllProductsIfSelected(campaignId);
      if (isAllSelected) {
        const productInCart = globalCart.find(item => item?.id === tempCampaign.id);
        if (productInCart) {
          const index = globalCart.indexOf(productInCart);
          globalCart.splice(index, 1);
        }

        setGlobalCart([...globalCart, { ...tempCampaign }]);
      }
    },
    [globalCart, checkDatesOfAllProductsIfSelected, setGlobalCart]
  );

  return {
    onDateChangeOfProduct,
    onMultipleDateChange,
    findMaximumBookingDurationInCampaignData,
    findMinimumFromDateInCampaignData,
    findMaximumToDateInCampaignData,
    getMinimumStartDayForCampaignData,
    getMinimumEndDayForCampaignData,
    calculatePriceOfSingleProductInCampaignData,
    calculateTotalPriceOfCampaignData,
    findCampaignById,
    findProductInCampaignById,
    setCampaignData,
    checkDatesOfAllProductsIfSelected,
    onAddToCart
  };
};

export default useCampaignData;
