import apiHelper from '../../helper/apiHelper';

const saveCustomizedCampaign = async (data, token) => {
  return await apiHelper(`/create_customized_campaign/`, 'POST', data, token)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default saveCustomizedCampaign;
