import { useState, useCallback } from 'react';
import checkProductAvailabilityStatus from '../api/checkProductAvailabilityStatus';
import { useCustomizedCampaignContext } from '../contexts/CustomizedCampaignContext';
import useCampaignDateHandlers from './campaignDateHandlers';
import useCampaignPrice from './campaignPrice';

const useCustomizedCampaign = () => {
  const { customizedCampaign, setCustomizedCampaign } = useCustomizedCampaignContext();
  const [productAvailability, setProductAvailability] = useState([]);
  const {
    findMaximumBookingDurationInCampaign,
    findMaximumToDateInCampaign,
    findMinimumFromDateInCampaign,
    getMinimumEndDayForCampaign,
    getMinimumStartDayForCampaign
  } = useCampaignDateHandlers();

  const { calculatePriceOfSingleProductInCampaign, calculateTotalPriceOfCampaign } = useCampaignPrice();

  const [nameDisabled, setNameDisabled] = useState(true);

  const changeCustomizedCampaignName = useCallback(
    name => {
      if (name.length === 0) {
        setCustomizedCampaign({
          ...customizedCampaign,
          name: 'Untitled Campaign'
        });
      }
      setCustomizedCampaign({ ...customizedCampaign, name });
    },
    [customizedCampaign, setCustomizedCampaign]
  );

  const toggleChangeName = useCallback(
    state => {
      setNameDisabled(state);
    },
    [setNameDisabled]
  );

  const checkIfCustomizedCampaignIsEmpty = useCallback(
    () => (customizedCampaign.products.length === 0 && customizedCampaign.name === 'Untitled Campaign' ? true : false),
    [customizedCampaign]
  );

  const setCustomizedCampaignToDefault = useCallback(() => {
    setCustomizedCampaign({
      name: 'Untitled Campaign',
      products: []
    });
  }, [setCustomizedCampaign]);

  const addProductInCustomizedCampaign = useCallback(
    (product, fromDate = null, toDate = null, name = null) => {
      if (customizedCampaign.products.find(p => p.product.id === product.id)) {
        return;
      }

      setCustomizedCampaign({
        ...customizedCampaign,
        name: name ? name : customizedCampaign.name,
        products: [
          ...customizedCampaign.products,
          {
            from_date: fromDate ? fromDate : null,
            to_date: toDate ? toDate : null,
            product: {
              id: product.id,
              name: product.name,
              price: product.price,
              unavailable_dates: product.unavailable_dates,
              booking_duration: product.booking_duration,
              images: product.images
            }
          }
        ].sort((a, b) => a.product.id - b.product.id)
      });
    },
    [customizedCampaign, setCustomizedCampaign]
  );

  const checkIfProductIsInCustomizedCampaign = useCallback(
    productId => {
      return customizedCampaign.products.some(item => item.product.id === productId);
    },
    [customizedCampaign]
  );

  const removeProductFromCustomizedCampaign = useCallback(
    product => {
      setCustomizedCampaign({
        ...customizedCampaign,
        products: customizedCampaign.products.filter(item => item.product.id !== product.id)
      });
    },
    [customizedCampaign, setCustomizedCampaign]
  );

  const addMultipleProductsInCustomizedCampaign = useCallback(
    (products, from_date = null, to_date = null) => {
      const productsToAdd = products.filter(product => !checkIfProductIsInCustomizedCampaign(product.id));
      setCustomizedCampaign({
        ...customizedCampaign,
        products: [...customizedCampaign.products, ...productsToAdd.map(product => ({ product, from_date, to_date }))].sort(
          (a, b) => a.product.id - b.product.id
        )
      });
    },
    [customizedCampaign, setCustomizedCampaign, checkIfProductIsInCustomizedCampaign]
  );

  const removeMultipleProductsFromCustomizedCampaign = useCallback(
    products => {
      setCustomizedCampaign({
        ...customizedCampaign,
        products: customizedCampaign.products.filter(item => !products.some(product => product.id === item.product.id))
      });
    },
    [customizedCampaign, setCustomizedCampaign]
  );

  const onDateChange = useCallback(
    (product, date, type) => {
      const newCustomizedCampaign = [...customizedCampaign.products];
      const index = newCustomizedCampaign.findIndex(item => item.product.id === product.id);

      if (index === -1) {
        return addProductInCustomizedCampaign(product, type === 'start' ? date : null, type === 'end' ? date : null);
      } else {
        const customizedCampaignProduct = newCustomizedCampaign[index];

        if (type === 'start') {
          customizedCampaignProduct.from_date = date;
          customizedCampaignProduct.to_date = null;
        } else if (type === 'end') {
          customizedCampaignProduct.to_date = date;
        }
      }

      setCustomizedCampaign({
        ...customizedCampaign,
        products: newCustomizedCampaign
      });
    },
    [customizedCampaign, setCustomizedCampaign, addProductInCustomizedCampaign]
  );

  const calculatePriceOfSingleProductInCustomizedCampaign = useCallback(
    item => calculatePriceOfSingleProductInCampaign(item),
    [calculatePriceOfSingleProductInCampaign]
  );

  const calculateTotalPriceOfCustomizedCampaign = useCallback(
    () => calculateTotalPriceOfCampaign(customizedCampaign),
    [customizedCampaign, calculateTotalPriceOfCampaign]
  );

  const onDateChangeOfWholeCampaign = useCallback(
    async (date, type) => {
      const newCustomizedCampaign = [...customizedCampaign.products];

      newCustomizedCampaign.forEach(item => {
        if (type === 'start') {
          item.from_date = date;
          item.to_date = null;
        } else if (type === 'end') {
          item.to_date = date;
        }
      });

      setCustomizedCampaign({
        ...customizedCampaign,
        products: newCustomizedCampaign
      });

      if (type === 'end') {
        const data = newCustomizedCampaign.map(item => ({
          product_id: item.product.id,
          from_date: item.from_date,
          to_date: item.to_date
        }));
        const result = await checkProductAvailabilityStatus(data);
        setProductAvailability(result.data.sort((a, b) => a.product_id - b.product_id));
      }
    },
    [customizedCampaign, setCustomizedCampaign]
  );

  const findMaximumBookingDurationInCustomizedCampaign = useCallback(
    () => findMaximumBookingDurationInCampaign(customizedCampaign),
    [customizedCampaign, findMaximumBookingDurationInCampaign]
  );

  const findMinimumFromDateInCustomizedCampaign = useCallback(() => {
    if (customizedCampaign.products.length === 0) {
      return '';
    }
    return findMinimumFromDateInCampaign(customizedCampaign) || '';
  }, [customizedCampaign, findMinimumFromDateInCampaign]);

  const findMaximumToDateInCustomizedCampaign = useCallback(() => {
    if (customizedCampaign.products.length === 0) {
      return;
    }
    return findMaximumToDateInCampaign(customizedCampaign) || '';
  }, [findMaximumToDateInCampaign, customizedCampaign]);

  const getMinimumStartDayForCustomizedCampaign = useCallback(() => {
    if (customizedCampaign.products.length === 0) {
      return;
    }
    return getMinimumStartDayForCampaign(customizedCampaign) || '';
  }, [customizedCampaign, getMinimumStartDayForCampaign]);

  const getMinimumEndDayForCustomizedCampaign = useCallback(() => {
    if (customizedCampaign.products.length === 0) {
      return;
    }
    return getMinimumEndDayForCampaign(customizedCampaign) || '';
  }, [customizedCampaign, getMinimumEndDayForCampaign]);

  const getProductIfInCustomizedCampaign = useCallback(
    productId => {
      return customizedCampaign.products.find(item => item.product.id === parseInt(productId));
    },
    [customizedCampaign]
  );

  return {
    changeCustomizedCampaignName,
    getMinimumEndDayForCustomizedCampaign,
    getMinimumStartDayForCustomizedCampaign,
    toggleChangeName,
    nameDisabled,
    addProductInCustomizedCampaign,
    checkIfCustomizedCampaignIsEmpty,
    setCustomizedCampaignToDefault,
    checkIfProductIsInCustomizedCampaign,
    removeProductFromCustomizedCampaign,
    addMultipleProductsInCustomizedCampaign,
    removeMultipleProductsFromCustomizedCampaign,
    onDateChange,
    calculateTotalPriceOfCustomizedCampaign,
    findMaximumBookingDurationInCustomizedCampaign,
    findMinimumFromDateInCustomizedCampaign,
    findMaximumToDateInCustomizedCampaign,
    onDateChangeOfWholeCampaign,
    getProductIfInCustomizedCampaign,
    calculatePriceOfSingleProductInCustomizedCampaign,
    productAvailability,
    setCustomizedCampaign
  };
};

export default useCustomizedCampaign;
