import {memo, useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import useNavbarTransparent from '../../hooks/navbarTransparent';
import CustomizeCampaignNavbar from '../Buttons/CustomizeCampaignNavbar';
import useLoginOrProfile from '../../utils/loginOrProfile';

const routes = [
        { name: 'Profile', code: 'profile' },
        { name: 'My Orders', code: 'my-orders'},
        { name: 'Logout', code: 'logout' },
];



const HomeNavbar = memo(({ theme = 'purple', text = 'black', page = '', checkCampaign, transparent = false }) => {
  const setLoginOrProfile = useLoginOrProfile();
  const navigate = useNavigate();
  const [selectedRoute, setSelectedRoute] = useState()
  const [name, setName] = useState('User')
  const [routes, setRoutes] = useState([
        { name: 'Profile', code: 'profile' },
        { name: 'My Orders', code: 'my-orders'},
        { name: 'Logout', code: 'logout' },
  ])

  useEffect(() => {
      const data = JSON.parse(sessionStorage.getItem('user_details'))
      if (data && data.userData.payload.first_name) {
        setName(data.userData.payload.first_name)
      }
  },[])

  const onRouteChange = (e) => {
    if (e.value.code === "logout") {
      sessionStorage.clear()
      navigate(0)
      return
    }
    setSelectedRoute(e.value.name)
    navigate("/" + e.value.code)
  }

  return (
    <div
      className={`py-6 px-12 z-30 fixed top-0 left-0 w-full font-theme-2 drop-shadow-custom-1  ${
        useNavbarTransparent() && transparent ? 'bg-transparent' : theme === 'purple' ? 'bg-white' : 'bg-theme-night-black-2'
      }`}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row items-center justify-center">
          <Link to="/">
            <img src={`/assets/images/logo/${theme}-${text}-logo.png`} alt="logo" />
          </Link>
          <div className={`text-${text} text-bold pl-12 flex flex-row items-center justify-center`}>
            <p className="text-normal px-4">Product</p>
            <p className="text-normal px-4">Solutions</p>
            <p className="text-normal px-4">Customers</p>
            <p className="text-normal px-4">Resources</p>
            <p className="text-normal px-4">Company</p>
          </div>
        </div>
        <div className={`flex flex-row items-center justify-center text-${text}`}>
          <Link to="/cart">
            <div className="flex flex-row items-center justify-center px-4">
              <img src={`/assets/icons/cart-${text}.png`} alt="cart" className="w-5 h-5" />
              <p className="text-normal pl-3">Cart</p>
            </div>
          </Link>
          <div className="flex flex-row items-center justify-center pl-4">
            {page !== 'customize campaign' ? (
              <Link to="/customize">
                <CustomizeCampaignNavbar theme={theme} />
              </Link>
            ) : (
              <CustomizeCampaignNavbar theme={theme} onClick={checkCampaign} />
            )}
            {setLoginOrProfile === 'login' ? (
              <Link to="/verify-mobile">
                <button
                  className={`bg-transparent border border-${
                    text === 'black' ? 'theme-purple-1' : 'white'
                  } font-bold py-2 px-6 rounded-lg focus-within:outline-none text-${text === 'black' ? 'theme-purple-1' : 'white'}`}
                >
                  Login
                </button>
              </Link>
            ) : (
               <Dropdown value={selectedRoute} options={routes} onChange={onRouteChange} optionLabel="name" placeholder={name} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default HomeNavbar;
