import { memo } from 'react';
import Carousel from 'react-multi-carousel';

const CustomDot = memo(({ onClick, ...rest }) => {
  const { active } = rest;
  return (
    <button className={(active ? 'active' : 'inactive') + ' mx-1.5'} onClick={() => onClick()}>
      <img src={`/assets/svg/carousel-indicator${!active ? '-disabled' : ''}.svg`} alt="" className="h-4 w-4" />
    </button>
  );
});

const MultiCarousel = memo(({ children, responsive }) => {
  return (
    <Carousel
      itemClass="shadow-3xl mx-6"
      className="overflow-visible "
      customDot={<CustomDot />}
      showDots={true}
      renderDotsOutside={true}
      dotListClass={'-bottom-8'}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      pauseOnHover={true}
      responsive={responsive}
    >
      {children}
    </Carousel>
  );
});

export default MultiCarousel;
