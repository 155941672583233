import Button from '../components/Button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Text from '../components/Text';
import OTPInput from 'otp-input-react';
import { memo } from 'react';

const VerifyMobileScreen = memo(props => (
  <div className="flex flex-row items-center justify-center w-full h-screen font-theme-2">
    <div className="flex flex-col items-start justify-center h-full w-full">
      <div className="grid grid-cols-7/20 w-full h-full">
        <div
          className="w-full bg-bottom bg-cover h-full bg-no-repeat"
          style={{
            backgroundImage: `url(/assets/images/login-image.png)`
          }}
        >
          <div className="flex flex-col h-full w-full">
            <img src="/assets/images/logo/orange-white-logo.png" alt="logo" className="w-fit p-4" />
          </div>
        </div>
        <div className="flex flex-col items-start justify-center pl-20 w-fit">
          <h2 className="text-2xl text-theme-night-black-2 font-bold text-center">Login to Adarth</h2>
          <p className="text-base text-theme-night-black">Please enter your phone number to login</p>
          {!props.verificationId && (
            <form onSubmit={props.callSubmitPhoneNumber} className="pt-6 w-full">
              <h6 className="text-base text-theme-gray-4">Phone Number</h6>
              <input
                className="px-6 py-2 rounded shadow text-left border my-4 w-full"
                onChange={props.callSetMobile}
                type="number"
                required
                placeholder="Please enter Phone Number"
              />
              <div className="flex flex-row w-full">
                <Button type="submit" className="disabled:bg-violet-400 bg-theme-purple-1 w-full" disabled={props.disabled}>
                  Request OTP
                </Button>
              </div>
            </form>
          )}
          {props.verificationId && !props.loading && (
            <form onSubmit={props.callSubmitOtp}>
              <div className="flex flex-col items-start justify-start">
                <div className="text-normal pt-10">
                  <Text>Enter OTP:</Text>
                  <OTPInput
                    value={props.otp}
                    onChange={props.setOtp}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    className="my-4 text-black"
                    inputStyles={{
                      height: '2.5rem',
                      width: '2.5rem'
                    }}
                    inputClassName="border rounded-md text-black"
                    secure={false}
                  />
                </div>
                <div className="flex flex-row items-center justify-center w-full py-2">
                  <Button type="submit" className="w-3/5 disabled:bg-gray-500" disabled={props.otpDisabled}>
                    Submit OTP
                  </Button>
                </div>
                <div className="flex flex-row items-end justify-end w-full py-1">
                  {!props.disableResend && (
                    <button onClick={props.callSubmitPhoneNumber}>
                      <Text className="mr-2 font-medium">Resend Otp</Text>
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
          {props.loading && <ProgressSpinner />}
        </div>
      </div>
    </div>
  </div>
));

export default VerifyMobileScreen;
