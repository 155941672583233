import apiHelper from '../../helper/apiHelper';

const findCampaignType = async campaignId => {
  return await apiHelper('/find_campaign_type', 'GET', { campaign_id: campaignId }, null)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });
};

export default findCampaignType;
