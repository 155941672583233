import CalendarTemplate from './CalendarTemplate';

const SingleProductCalendar = ({
  type,
  product,
  onDateChange,
  productInCampaign,
  getValueOfDate,
  getMinimumEndDayOfItem,
  getMinimumEndDay,
  convertDatesArray,
  onAddToCartCall,
  onAddToCart,
  checkCampaignIfInCart,
  campaignId,
  campaignType
}) => {
  return (
    <CalendarTemplate
      onChange={e => {
        onDateChange(product, e.target.value, type);
        if (onAddToCartCall && checkCampaignIfInCart(campaignType, campaignId)) {
          onAddToCart();
        }
      }}
      value={getValueOfDate(type === 'start' ? productInCampaign?.from_date : productInCampaign?.to_date) || ''}
      placeholder={type === 'start' ? 'Start Date' : 'End Date'}
      minDate={
        type === 'start'
          ? getMinimumEndDay(product.id)
          : productInCampaign && getMinimumEndDayOfItem(productInCampaign.from_date, productInCampaign.product.booking_duration)
      }
      disabledDates={convertDatesArray(product.unavailable_dates)}
      required
    />
  );
};

export default SingleProductCalendar;
